import React, { Component } from 'react';
import {t} from '../Translate';
import './ScorezMsg.css';

export default class ErrorMsg extends Component {

  render() {
    return (
      <div className="alert alert-danger game-alert">
        <strong>{ t(this.props.type) } </strong> { t(this.props.msg) }
      </div>
      )
  }

}