import React, { Component } from 'react';

import ErrorMsg from '../msg/ErrorMsg';
import LoadingMsg from '../msg/LoadingMsg';

import {lang} from '../Translate';
import moment from "moment"

class GameHeader extends Component {

  constructor(props) {
    super(props);

    this.state = {
        game_name: false,
        game_organizer: "",
        game_date: "",
        data: false,
        is_owner: null,
        public: null
    };

    this.game_data = props.game_data
  }

  componentDidMount() {
    this.game_data.loadGame(this.loadGame.bind(this))

    this.updateStore()
    this.unsubscribe = window.store.subscribe(this.updateStore.bind(this))
  }

  updateStore() {
    var role = window.store.getState().role

    if(role !== null) {
      this.setState({
        is_owner: window.store.getState().role === "owner"
      })
    }
  }

  componentWillUnmount() {
    this.unsubscribe()
  }

  loadGame(game)
  {
    this.setState({
        game_name: game.name,
        game_organizer: game.organizer,
        game_date: game.date,
        data: game,
        public: game.public
    });
  }

  ownershipReady() {
    return this.state.public !== null && this.state.is_owner !== null
  }

  render() {
    if(this.state.game_date !== "") {
      var date = moment(this.state.game_date, "YYYY-MM-DD")
      date.locale(lang)
      var month = date.format("MMM")
      var day = date.format("DD")
      var year = date.format("YYYY")
    }

    return (
      <div>
        { !this.ownershipReady() &&
          <LoadingMsg type="info" msg="loading" /> }
        { this.ownershipReady() && !(this.state.public || this.state.is_owner) &&
          <ErrorMsg type="error" msg="unauthorized" />
        }
        { (this.state.public || this.state.is_owner) &&
          <div>
            { this.state.game_name &&
              <div className="game-header">
              { this.state.game_date !== "" &&
                <span className="date">
                  <span className="month">
                  {month}
                  </span>
                  <span className="day">
                  {day}
                  </span>
                  <span className="year">
                  {year}
                  </span>
                </span>
              }
              <h2>{this.state.game_name}</h2>
              {this.state.game_organizer}
              </div>
            }
          </div>
      }
    </div>
    );
  }
}

export default GameHeader;
