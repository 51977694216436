import { TweenMax, TimelineMax } from 'gsap';
import AbstractAnimator from './AbstractAnimator'

export default class DropAnimator extends AbstractAnimator {

  constructor(props) {
    super(props)

    this.transition_time1 = 2
    this.transition_time2 = 2
    this.transition_time = this.transition_time1 + this.transition_time2
    this.show_before = true
  }

  animate() {
    this.is_animating = true
    this.vars.tl = new TimelineMax({
      delay: 0.5,
      repeat: 0,
      repeatDelay: 2,
      onUpdate: this.updateScore.bind(this)
    });

    this.vars.tl.add("StartPoint");

    let reverseOrderItems = this.items.sort(this.sorting.reverseSortAfterTeamName)

    reverseOrderItems
      .forEach((item, index) => {
        this.vars.tl.add(function (item) { item.updateCount = Date.now() }.bind(this, item),
          "StartPoint+=" + (index * 0.03))
      })

    reverseOrderItems
      .forEach((item, index) => {

        this.vars.tl.add(TweenMax
          .to(item.scoreitemElement, this.transition_time1,
            {
              opacity: 0,
            }), "StartPoint+=" + (index*0.01))

      })

    this.vars.tl.add("MidPoint");

    reverseOrderItems
      .forEach((item, index) => {

        this.vars.tl.set(item.scoreitemElement,
            {
              x: this.columnLayoutX(item, item.positionAfter),
              y: this.columnLayoutY(item, item.positionAfter),
            }, "MidPoint+=" + (index * 0.01))

        this.vars.tl.add(TweenMax
          .to(item.scoreitemElement, this.transition_time2,
            {
              opacity: 1,
            }), "MidPoint+=" + (index * 0.01))

      })

    this.vars.tl.add(TweenMax.to(this, 5))
    this.vars.tl.add(function (item) {
      this.is_animating = false
      this.has_animated = true
    }.bind(this))
  }
}