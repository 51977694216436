import React, { Component } from 'react';
import Superagent from 'superagent';

export default class CodeGenerator extends Component {

    constructor(props) {
      super(props);
  
      this.state = {
        dataRoute: window.apiUrl + "/scorez-api/generatecodes.php",
        codes: []
      }
  
      this.handleError = this.props.handleError;
    }
    
    generate()
    {
        window.identity.getToken(
            /* Success callback */
          (idToken) => {
            Superagent
              .post(this.state.dataRoute)
              .send({user: idToken})
              .set('Accept', 'application/json')
              .end(function(err,res){
                if (!this.handleError("Error generating codes", err, res)) {
                    this.setState({
                        codes: res.body
                    });
                }
              }.bind(this))
            })
    }

    render() {
        return (
            <div>
                <h1>Generate Codes</h1>
                <button className="btn" onClick={this.generate.bind(this)}>Generate</button>
                { this.state.codes.map(
                    (code) => <p>{code}</p>
                )}
            </div>
        )
    }
};