import { TweenMax, TimelineMax, Linear } from 'gsap';
import AbstractAnimator from './AbstractAnimator'

export default class ShuffleFakeyAnimator extends AbstractAnimator {

  constructor(props) {
    super(props)

    this.transition_time1 = 4
    this.transition_time2 = 4
    this.transition_time = this.transition_time1 + this.transition_time2
  }

  animate() {
    this.is_animating = true
    this.vars.tl = new TimelineMax({
      delay: 1,
      repeat: 0,
      repeatDelay: 2,
      onUpdate: this.updateScore.bind(this)
    });

    // let itemsPerCol = this.itemsPerColumn()

    this.vars.tl.add("StartPoint");

    this.items
      .sort(this.sorting.reverseSortAfterTeamName)
      .forEach((item, index) => {
        this.vars.tl.add(function (item) { item.updateCount = Date.now() }.bind(this, item),
          "StartPoint+=" + (index * 0.03))
      })

    this.items
      .sort(this.sorting.randomOffsetSortAfter)
      .forEach((item, index) => {
        let xNext = this.columnLayoutX(item, index + 1)
        let yNext = this.columnLayoutY(item, index + 1)
        item.xPrev = xNext
        item.yPrev = yNext
        this.vars.tl.add(TweenMax
          .to(item.scoreitemElement, this.transition_time1,
            {
              x: xNext,
              y: yNext,
              ease: Linear.easeNone,
            }), "StartPoint+=" + (index * 0.03))

      })

    this.vars.tl.add("MidPoint");

    this.items
      .sort(this.sorting.reverseSortAfterTeamName)
      .forEach((item, index) => {
        //let xNext = this.columnLayoutX(item, item.positionAfter)
        //let yNext = this.columnLayoutY(item, item.positionAfter)
        //if (Math.abs(xNext - item.xPrev) > 20 /* Magic threshold */ && item.positionAfter > item.positionBefore) {
        //  let deltaPosCol = item.positionBefore % itemsPerCol
        //  let deltaPos = item.positionAfter - item.positionBefore
        //  let interp = deltaPosCol / deltaPos
        //  let t1 = this.transition_time2 * interp
        //  let t2 = this.transition_time2 * (1-interp)
        //  this.vars.tl.add(TweenMax
        //    .to(item.scoreitemElement, t1,
        //      {
        //        x: this.columnLayoutX(item, item.xPrev),
        //        y: this.columnLayoutY(item, item.positionAfter - (item.positionAfter % itemsPerCol))
        //      }), "MidPoint")
        //  this.vars.tl.add(TweenMax
        //    .set(item.scoreitemElement,
        //      {
        //        x: this.columnLayoutX(item, item.positionAfter),
        //        y: this.columnLayoutY(item, item.positionAfter)
        //      }), "MidPoint+=" + (t1))
        //} else {
        //  this.vars.tl.add(TweenMax
        //    .to(item.scoreitemElement, this.transition_time2,
        //      {
        //        x: this.columnLayoutX(item, item.positionAfter),
        //        y: this.columnLayoutY(item, item.positionAfter)
        //      }), "MidPoint")
        //}

        this.vars.tl.add(TweenMax
          .to(item.scoreitemElement, this.transition_time2,
            {
              x: this.columnLayoutX(item, item.positionAfter),
              y: this.columnLayoutY(item, item.positionAfter),
              ease: Linear.easeNone,
            }), "MidPoint-=2")
      })

    this.vars.tl.add(TweenMax.to(this, 5))
    this.vars.tl.add(function (item) {
      this.is_animating = false
      this.has_animated = true
    }.bind(this))
  }
}