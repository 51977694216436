import React, { Component } from 'react';
import Superagent from 'superagent';

import Title from '../Title';
import Nav from '../Nav';
import Footer from '../Footer';
import {GameLink} from './GameLink';
//import moment from 'moment';

import {t} from '../Translate';

export default class MyGames extends Component {

  constructor(props) {
    super(props);

    this.state = {
      dataRoute: window.apiUrl + "/scorez-api/games.php",
      error_msg: false,
      user_games: false,
      user: null
    }

    this.handleError = this.props.handleError;
  }

  componentDidMount() {
    this.unsubscribe = window.store.subscribe(this.updateStore.bind(this))
  }

  componentWillUnmount() {
    this.unsubscribe()
  }

  updateStore() {
    var user = window.store.getState().user

    this.setState({
        user: user,
        user_games: false,
    })

    window.identity.getToken(
        /* Success callback */
      (idToken) => {
        Superagent
          .post(this.state.dataRoute)
          .send({user: idToken})
          .set('Accept', 'application/json')
          .end(function(err,res){
            if (!this.handleError("Error fetching games", err, res)) {
                this.setState({
                    user_games: res.body
                });
            }
          }.bind(this))
        })
  }

  render() {
    return (
        <div id="my_games" class="d-flex flex-column h-100">
          <Title />
          <Nav />

          <div className="games">
            <h2>{t("latest_user_scorez")}</h2>
            { this.state.user_games &&
              this.state.user_games
              .map((game) =>
                <GameLink key={game.id} game={game} />
              )
            }
            { this.state.user_games && this.state.user_games.length === 0 &&
                <div className="alert alert-warning">{t("no_scorez")}</div>
            }
            { !this.state.user_games &&
                <div className="alert alert-primary">{t("loading")}</div>
            }
          </div>
          <div style={{clear:"both"}} />
          <Footer />
        </div>
      )
  }
}