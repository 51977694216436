import React, { Component } from 'react';
import GameHome from './GameHome';
import GameSheet from './GameSheet';
import Scoreboard from './Scoreboard';
import GameSettings from './GameSettings';
import GameExportCSV from './GameExportCSV';
import GameData from './GameData';
// import Login from '../Login'
import './Game.css';

import {t} from '../Translate';
import {scorezPath, relativeScorezPath} from '../Helpers';

import { BrowserRouter } from 'react-router-dom'
import { Switch, Route } from 'react-router'
import { push as Menu } from 'react-burger-menu'
import AlertContainer from 'react-alert'
var FontAwesome = require('react-fontawesome');

class Game extends Component {

  alertOptions = {
    offset: 14,
    position: 'bottom left',
    theme: 'dark',
    time: 5000,
    transition: 'scale'
  }

  constructor(props) {
    super(props);

    this.state = {
        game: this.props.match.params.game,
        user: null,
        is_owner: false,
        role: null
    };

    this.game_data = new GameData({
      ...props,
      game_id: this.props.match.params.game
    })

    this.handleError = this.props.handleError
  }

  componentDidMount() {
    this.updateStore()
    this.unsubscribe = window.store.subscribe(this.updateStore.bind(this))
  }

  updateStore() {

    this.game_data.getRole((role) => {

      if(this.state.role !== role) {
        this.setState({
          is_owner: role === "owner",
          role: role
        })

        window.store.dispatch({
          type: "ROLE",
          role: role
        })
      }

    })

  }

  componentWillUnmount() {
    this.unsubscribe()
  }

  showSettings (event) {
    event.preventDefault();
  }

  render() {
    return (
      <div id="outer-container">

        <AlertContainer ref={a => this.msg = a} {...this.alertOptions} />

        <Menu width={ '180px' } pageWrapId={'page-wrap'} outerContainerId={ "outer-container" }>
          <span id="scorez">Scorez</span>
          <a id="gamelink" className="menu-item" href={relativeScorezPath("/game/home/" + this.state.game)}><FontAwesome name="trophy" /> { t("teams") }</a>
          <a id="scoreboardlink" className="menu-item" href={relativeScorezPath("/game/scoreboard/" + this.state.game)}><FontAwesome name="star-o" /> { t("scoreboard") }</a>
          <a id="sheetlink" className="menu-item" href={relativeScorezPath("/game/sheet/" + this.state.game)}><FontAwesome name="table" /> { t("sheet") }</a>
          { this.state.is_owner && <a id="settingslink" className="menu-item" href={relativeScorezPath("/game/settings/" + this.state.game)}><FontAwesome name="wrench" /> { t("settings") }</a> }
          { this.state.is_owner && <a id="csvlink" className="menu-item" href={relativeScorezPath("/game/exportcsv/" + this.state.game)}><FontAwesome name="bars" /> { t("export_csv") }</a> }

          <hr />
          <a id="homelink" className="menu-item" href={relativeScorezPath("")}><FontAwesome name="home" /> { t("home") }</a>
        </Menu>
        { !window.location.pathname.includes("scoreboard") &&
          <div className="game-navbar">
            <span className="game-navbar-title">Scorez</span>
            {/* <Login display="navbar" /> */}
            &nbsp; <a className="game-link" href={relativeScorezPath("/game/scoreboard/" + this.state.game)}>{t("scoreboard")}</a>
            &nbsp;| <a className="game-link" href={relativeScorezPath("/game/sheet/" + this.state.game)}>{t("sheet")}</a>

            <div style={{clear:"both"}} />
          </div>
        }
        <main id="page-wrap">
          <BrowserRouter>
            <Switch>
              <Route path={scorezPath('/game/home/:game')} render={(props) => (
                  <GameHome {...props} game_data={this.game_data} handleError={this.handleError} />
              )} />
              <Route path={scorezPath('/game/sheet/:game')} render={(props) => (
                  <GameSheet {...props} game_data={this.game_data} handleError={this.handleError} />
              )} />
              <Route path={scorezPath('/game/scoreboard/:game')} render={(props) => (
                  <Scoreboard {...props} game_data={this.game_data} handleError={this.handleError} />
              )} />
              <Route path={scorezPath('/game/exportcsv/:game')} render={(props) => (
                  <GameExportCSV {...props} game_data={this.game_data} handleError={this.handleError} />
              )} />
              <Route path={scorezPath('/game/settings/:game')} render={(props) => (
                  <GameSettings {...props} game_data={this.game_data} handleError={this.handleError} />
              )} />
            </Switch>
          </BrowserRouter>
        </main>

      </div>
    );
  }
}

export default Game;
