import React, { Component } from 'react';

import Title from './Title';
import Nav from './Nav';
import Footer from './Footer';

import {t} from './Translate';

export default class Contact extends Component {

  render() {
    return (
        <div id="contact" class="d-flex flex-column h-100">
          <Title />
          <Nav />

          <div className="contact">
            <h2>{t("contact")}</h2>

            <iframe title="contact" src="https://docs.google.com/forms/d/e/1FAIpQLSdDlm2zFTlhJocS75bTM8ycjG1uY910kSk2LFsSAItdq23HhA/viewform?embedded=true" width="640" height="510" frameBorder="0" marginHeight="0" marginWidth="0">Loading...</iframe>

          </div>
          <div style={{clear:"both"}} />
          <Footer />
        </div>
      )
  }
}