import React, { Component } from 'react';
//import Donate from './Donate';
//import t from "./Translate"

class Footer extends Component {

  render() {
    return (
    <div className="mt-auto footer bg-dark text-light text-center">
      © 2020 - Meerdael Automation
    </div>
    );
  }
}

export default Footer;
