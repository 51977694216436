import React, { Component } from 'react';
import './App.css';
import Game from './game/Game';
import Home from './Home';
import Identity from './Identity';
import CodeGenerator from './CodeGenerator';
import CreateGame from './game/CreateGame';
import MyGames from './game/MyGames';
import BrowseGames from './game/BrowseGames';
import Profile from './Profile';
import Contact from './Contact';

import scorezPath from './Helpers';

import AlertContainer from 'react-alert'

import { BrowserRouter } from 'react-router-dom';
import { Switch, Route } from 'react-router';

import { createStore } from 'redux'
import { scorezReducer } from './Reducers'

window.apiUrl = (window.location.hostname === "localhost")? "http://" + window.location.hostname : window.location.origin
window.rootFolder = ""
//DEBUG
//window.apiUrl = "https://scorez.be"

export const initStore = (reducer) => {
  var initialState = window.localStorage.getItem('state')

  try
  {
    return createStore(reducer, JSON.parse(initialState))
  }
  catch(err)
  {
    console.log("Init store error")
    console.log(err)
    return createStore(reducer)
  }
}

// Redux Store
window.store = initStore(scorezReducer);
window.identity = new Identity();


class App extends Component {

  alertOptions = {
    offset: 14,
    position: 'bottom left',
    theme: 'dark',
    time: 5000,
    transition: 'scale'
  }

  constructor(props)
  {
    super(props)

    this.handleError = this.handleError.bind(this)
  }

  componentDidMount() {
    this.unsubscribe = window.store.subscribe(this.updateStore.bind(this))
  }

  componentWillUnmount() {
      this.unsubscribe()
  }

  updateStore()
  {
    window.localStorage.setItem('state', JSON.stringify(window.store.getState()))
  }

  showSettings (event) {
    event.preventDefault();
  }

  handleError(title, err, res, type="error") {
    // console.log("error")
    // console.log(err);
    // console.log("result")
    // console.log(res);

    if( (res && res.statusCode !== 200)
        || err
        || (res && res.body && res.body.error))
    {
      console.log(title)
      if(res && res.body)
      {

        this.msg.show(title + ": " + ('error' in res.body)? res.body.error : res.body, {
          time: 5000,
          type: type,
        })
      }
      else
      {
        this.msg.show(title, {
          time: 5000,
          type: type,
        })
      }
      return true;
    }
    else
    {
      return false;
    }
  }

  render() {
    return (
      <div id="main">
        <AlertContainer ref={a => this.msg = a} {...this.alertOptions} />
        <BrowserRouter>
          <Switch>
            <Route exact path={"/generatecodes"} render={(props) => (
                  <CodeGenerator {...props} handleError={this.handleError} />
              )} />
            <Route exact path={scorezPath("/create")} render={(props) => (
                  <CreateGame {...props} handleError={this.handleError} />
              )} />
            <Route exact path={scorezPath("/browse")} render={(props) => (
                <BrowseGames {...props} handleError={this.handleError} />
            )} />
            <Route exact path={scorezPath("/mine")} render={(props) => (
                  <MyGames {...props} handleError={this.handleError} />
              )} />
            <Route exact path={scorezPath("/profile")} render={(props) => (
                  <Profile {...props} handleError={this.handleError} />
              )} />
            <Route exact path={scorezPath("/contact")} render={(props) => (
                  <Contact {...props} handleError={this.handleError} />
              )} />
            <Route exact path={window.rootFolder + '/'} render={(props) => (
                  <Home {...props} handleError={this.handleError} />
              )} />
            <Route exact path={scorezPath("")} render={(props) => (
                  <Home {...props} handleError={this.handleError} />
              )} />
            <Route path={scorezPath("/game/:task/:game")} render={(props) => (
                  <Game {...props} handleError={this.handleError} />
              )} />
          </Switch>
        </BrowserRouter>
      </div>
    );
  }
}

export default App;
