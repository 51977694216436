import { TweenMax, TimelineMax } from 'gsap';
import AbstractAnimator from './AbstractAnimator'

export default class ControlledDropAnimator extends AbstractAnimator {

    constructor(props) {
        super(props)

        this.transition_time = 1
        this.show_before = false
        this.current_team = 0
    }

    isOnCommand() {
      return true
    }

    sortedItems() {
      return this.items.sort(this.sorting.sortAfterTeamName)
    }

    animateNext() {
      this.vars.tl = new TimelineMax({
        delay: 0.5,
        repeat: 0,
        repeatDelay: 2,
        onUpdate: this.updateScore.bind(this)
      });

      let item = this.sortedItems()[this.current_team]

      this.vars.tl.add(TweenMax
        .set(item.scoreitemElement,
          {
            visibility: "visible",
            x: window.innerWidth / 2 - item.scoreitemElement.offsetWidth / 2,
            y: -200,
          }))

      this.vars.tl.add(function (item) { item.updateCount = Date.now() }.bind(this, item))

      // Scroll to position
      var yPos = this.columnLayoutY(item, item.positionAfter)
      this.vars.tl.add(function (item) { window.scrollTo(0, yPos) }.bind(this, item))

      this.vars.tl.add(TweenMax
        .to(item.scoreitemElement, 1,
          {
            x: this.columnLayoutX(item, item.positionAfter),
            y: yPos
          }), "-= 0.1")


      this.vars.tl.add(TweenMax.to(this, 5))
      this.vars.tl.add(function () {
        if (this.current_team === this.items.length) {
          this.is_animating = false
          this.has_animated = true
        }
      }.bind(this))

      this.current_team += 1
    }

    animate() {
      this.is_animating = true
      this.current_team = 0
      this.items.forEach((item) => item.positionBefore = 1)

      this.animateNext()
    }
}