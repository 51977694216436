import React, { Component } from 'react';
import './Nav.css';
import {t} from './Translate';
import scorezPath from "./Helpers";

export default class Nav extends Component
{
    constructor(props) {
        super(props);
    
        this.state = {
          user: null
        }
    }

    componentDidMount() {
        this.unsubscribe = window.store.subscribe(this.updateStore.bind(this))
        this.updateStore()
    }

    componentWillUnmount() {
        this.unsubscribe()
    }

    updateStore() {
      if (window.store.getState())
      {
        var user = window.store.getState().user

        this.setState({
            user: user
        })
      }
    }

    render()
    {
        // TODO mark li className as active for current page
        return (
<nav className="navbar navbar-expand-lg navbar-dark bg-dark">
  <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
    <span className="navbar-toggler-icon"></span>
  </button>
  <div className="collapse navbar-collapse" id="navbarNavDropdown">
    <ul className="navbar-nav">
      <li className="nav-item">
        <a className="nav-link" href={scorezPath("/")}>{t("home")}</a>
      </li>
      { this.state.user &&
        <li className="nav-item">
          <a className="nav-link" href={scorezPath("/create")}>{t("create_game")}</a>
        </li>
      }
      <li className="nav-item">
        <a className="nav-link" href={scorezPath("/browse")}>{t("browse")}</a>
      </li>
      { this.state.user && 
        <li className="nav-item">
          <a className="nav-link" href={scorezPath("/mine")}>{t("my_games")}</a>
        </li>
      }
      <li className="nav-item">
        <a className="nav-link" href={scorezPath("/contact")}>{t("contact")}</a>
      </li>
      <li className="nav-item dropdown">
        <a className="nav-link dropdown-toggle" href="#drop" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
        { t("change_language") }
        </a>
        <div className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
          <a className="dropdown-item" href="/en">English</a>
          <a className="dropdown-item" href="/nl">Nederlands</a>
        </div>
      </li>
    </ul>
  </div>
  { this.state.user && 
    <a className="navbar-brand" id="nav-profile" href={scorezPath("/profile")}>{this.state.user.displayName}</a>
  }
</nav>
        )
    }
};