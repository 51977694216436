import React, { Component } from 'react';
import './GameSettings.css';
import './GameSheet.css';

import GameHeader from './GameHeader';

import t from "../Translate";
import moment from 'moment';

import ReactTooltip from 'react-tooltip';

export default class GameSettings extends Component {

  constructor(props) {
    super(props);

    this.state = {
        exaequo_rules: [],
        selected_rule: "last-to-first",
        rounds: false,
        game: false,
        is_owner: false,
        picked_date: null,
        public_game: null
    };

    this.all_exaequo_rules = [
      "last-to-first",
      "first-to-last",
      "tie-breaker",
      "selected-round",
    ]

    this.game_data = props.game_data
    this.handleError = props.handleError;
  }

  componentDidMount() {

    this.game_data.loadGame(this.loadGame.bind(this))

    this.updateStore()
    this.unsubscribe = window.store.subscribe(this.updateStore.bind(this))
  }

  loadGame(game) {
    this.setState({
      game: game,
      picked_date: moment(game.date),
      public_game: game.public
    })
  }

  updateStore() {

    var callback = (round_data) => {
      var first_round = round_data.first_round
      var last_round = round_data.last_round
      var rounds = round_data.rounds

      this.setState({
        first_round,
        last_round,
        rounds
      })


      this.loadExAequoRules()
    }

    window.identity.getToken(
      /* Success callback */
      (idToken) => {
        this.game_data.loadRounds(idToken, callback)
      })

    this.setState({
      is_owner: window.store.getState().role === "owner"
    })
  }

  componentWillUnmount() {
    this.unsubscribe()
  }

  loadExAequoRules() {
    this.game_data.getExAequoRules((rules) => {
      if(rules && rules !== "") {
        this.setState({
          exaequo_rules: rules
        })
      }
    })
  }

  addExAequoRule(rule) {
    var new_rules = [].concat(this.state.exaequo_rules)

    var new_rule = {rule: rule}
    if(rule === "tie-breaker" || rule === "selected-round") {
      new_rule["round"] = this.state.first_round
    }
    new_rules.push(new_rule)

    this.setState({
      exaequo_rules: new_rules
    })
  }

  removeExAequoRule(index) {
    var new_rules = [].concat(this.state.exaequo_rules)

    new_rules.splice(index, 1)

    this.setState({
      exaequo_rules: new_rules
    })
  }

  updateExAequoRules(new_rules) {
    this.game_data.updateExAequoRules(this.state.exaequo_rules, () => {
      this.handleError(t("save_successful"), true, null, "info")
    })
  }

  exAequoRulesContain(rule) {
    return this.state.exaequo_rules
            .filter((rule_item) => rule_item.rule === rule)
            .length > 0
  }

  isExAequoRuleAvailable(rule) {
    if( (rule === "last-to-first" || rule === "first-to-last")
          &&
        (this.exAequoRulesContain("last-to-first")
          || this.exAequoRulesContain("first-to-last")
        )
      )
    {
      return false
    }

    if(rule === "selected-round"
          &&
        (this.exAequoRulesContain("last-to-first")
          || this.exAequoRulesContain("first-to-last")
        )
      )
    {
      return false
    }

    return true
  }

  roundSelectionChanged(rule_index, event) {
    var new_rules = [].concat(this.state.exaequo_rules)
    new_rules[rule_index]["round"] = event.target.value
    this.setState({
      exaequo_rules: new_rules
    })
  }

  answerChanged(rule_index, event) {
    var new_rules = [].concat(this.state.exaequo_rules)
    new_rules[rule_index]["answer"] = event.target.value
    this.setState({
      exaequo_rules: new_rules
    })
  }

  deleteGame() {
    // if(this.refs["delete-game-input"].value.toLowerCase()
    //     === t("yes_delete_game").toLowerCase())
    // {
      if(!window.confirm(t("delete_game_confirmation")))
      {
        return
      }

      this.game_data.deleteGame()
    // } else {
    //   this.handleError(t("enter_delete_game"), true, null)
    // }
  }

  renderRoundSelection(rule_index, rule_round) {
    return (
        <select className="round-selection"
                onChange={this.roundSelectionChanged.bind(this, rule_index)}
                defaultValue={rule_round}
        >
        { this.state.rounds.map((round, index) =>
            <option key={"round-" + index} value={index}>{round}</option>
          )
        }
        </select>
      )
  }

  renderSelectedRule(rule, index) {
    if(rule.rule === "selected-round") {
      return (
          <div>
            {index+1}. { t(rule.rule) }<br />
            <div className="ex-aequo-label">{ t("round") }</div>
            { this.renderRoundSelection(index, rule.round) }
          </div>
        )
    }
    if(rule.rule === "tie-breaker") {
      return (
          <div>
            {index+1}. { t(rule.rule) }<br />
            <div className="ex-aequo-label">{ t("round") }</div>
            { this.renderRoundSelection(index, rule.round) }<br />
            <div className="ex-aequo-label">{ t("answer") }</div>
            <input
              className="tie-breaker-answer"
              type="text"
              onChange={this.answerChanged.bind(this, index)}
              value={rule.answer}
            />
            { (isNaN(rule.answer) || rule.answer === "") && <span style={{color: "red"}}> { t("invalid") }</span> }
          </div>
        )
    }
    else {
      return (
          <div>
            {index+1}. { t(rule.rule) }
          </div>
        )
    }
  }

  renderAvailableRules() {
    return (
        <div>
          <h4>{ t("available_rules") }</h4>
          { this.all_exaequo_rules
            .filter((item) => this.isExAequoRuleAvailable(item))
            .map((item) =>

            <div key={"ex-aequo-rule-wrap-" + item}>
              <div key={"ex-aequo-rule-" + item} className="ex-aequo-rule">
                <div className="ex-aequo-rule-desc"
                     data-tip={ t(item+"-desc") }>
                { t(item) }
                </div>
                <button key={"create-ex-aequo-rule-" + item}
                    className="btn btn-primary"
                    onClick={this.addExAequoRule.bind(this, item)}
                    >{ t("add_rule") }</button>
              </div>
              <div style={{clear: "both"}} />
            </div>

            )
          }
        </div>
      )
  }

  renderSelectedRules() {
    return (
        <div>
          <h4>{ t("selected_rules") }</h4>
          <p>{ t("selected_rules_desc") }</p>
          { this.state.exaequo_rules.map((rule, index) =>

            <div key={"ex-aequo-rule-wrap-" + index}>
              <div key={"ex-aequo-rule-" + index} className="ex-aequo-rule">
                <div key={"ex-aequo-rule-desc-" + index}
                     className="ex-aequo-rule-desc"
                     data-tip={ t(rule.rule+"-desc") }>
                  {this.renderSelectedRule(rule, index)}
                </div>
                <button key={"create-ex-aequo-rule-" + index}
                    className="btn btn-primary"
                    onClick={this.removeExAequoRule.bind(this, index)}
                    >{ t("remove_rule") }</button>
              </div>
              <div style={{clear: "both"}} />
            </div>

            )
          }
          { this.state.exaequo_rules.length === 0 && <b>{ t("no_rules") } </b>}

            <button key={"save-ex-aequo-rules"}
                    className="btn btn-primary"
                    onClick={this.updateExAequoRules.bind(this)}
                    >{ t("save_exaequo_rules") }</button>
        </div>
      )
  }

  editGame() {
    // var name = this.refs["edit-game-name"].value
    // var organizer = this.refs["edit-game-organizer"].value
    // var date = this.state.picked_date.format("YYYY-MM-DD")
    var public_game = this.state.public_game? 1:0

    // if(name.trim() === "") {
    //   this.handleError(t("provide_name"), true, null)
    //   return
    // }
    // if(organizer.trim() === "") {
    //   this.handleError(t("provide_organizer"), true, null)
    //   return
    // }

    this.game_data.editGame(public_game, t("editing_game"))
  }

  renderDeleteGame() {
    return (
        <div>
          {/* <p>{ t("enter_delete_game") }</p>
          <div className="settings-input">
            <input type="text"
                   ref="delete-game-input"
                   />
          </div> */}
          <button
              className="btn btn-primary"
              onClick={this.deleteGame.bind(this)}
              >{ t("delete_game") }</button>
        </div>
      )
  }

  renderEditGame() {
    return (
      <div>
        <table className="edit-game-table">
        <tbody>
        {/* <tr>
           <th>{ t("name") }</th>
          <td><input type="text"
                 ref="edit-game-name"
                 defaultValue={this.state.game.name}
                 /></td>
        </tr>
        <tr>
          <th>{ t("organizer") }</th>
          <td><input type="text"
                 ref="edit-game-organizer"
                 defaultValue={this.state.game.organizer}
                 /></td>
        </tr>
        <tr>
          <th>{ t("date") }</th>
          <td><DatePicker
                     selected={this.state.picked_date}
                     onChange={(date) => this.setState({
                                          picked_date: date
                                          })}
                     dateFormat="YYYY-MM-DD"
                 /></td>
        </tr> */}
        <tr>
          <th>{ t("public") }</th>
          <td><input type="checkbox"
                 ref="edit-game-public"
                 onChange={(event) => this.setState({
                                       public_game: event.target.checked
                                      })}
                 checked={this.state.public_game}
                 /></td>
        </tr>
        <tr>
          <td><button
              className="btn btn-primary"
              onClick={this.editGame.bind(this)}
              >{ t("edit_game") }</button></td>
        </tr>
        </tbody>
        </table>
      </div>
      )
  }

  settingsReady() {
    return this.state.rounds && this.state.is_owner
  }

  render() {
    return (
      <div className="settings-content">
        {/* Add the length of the selected ex aequo rules to force re-render of the tooltip
            Otherwise, the tooltip did not work for the selected rules.
        */}
        <ReactTooltip key={"tooltip-" + this.state.exaequo_rules.length} effect="solid" place="right" offset={{right: -100}} />
        <GameHeader game_data={this.game_data} handleError={this.props.handleError} />
        <h2>{ t("settings") }</h2>
        { this.settingsReady() &&
          <div>

            <div className="settings">
              <h3>{ t("ex_aequo_rules") }</h3>

              { this.renderAvailableRules() }
              <hr />
              { this.renderSelectedRules() }
            </div>

            {this.state.game &&
              <div className="settings">
                <h3>{ t("edit_game") }</h3>
                { this.renderEditGame() }
              </div>
            }

            <div className="settings">
              <h3>{ t("delete_game") }</h3>
              { this.renderDeleteGame() }
            </div>

          </div>
        }

      </div>
      )
  }
}