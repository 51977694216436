import React, { Component } from 'react';
import GameHeader from './GameHeader';
import {t} from '../Translate';

export default class GameExportCSV extends Component {

  constructor(props) {
    super(props);

    this.state = {
      csv: false
    }

    this.handleError = this.props.handleError
  }

  componentDidMount() {
    this.updateStore()
    this.unsubscribe = window.store.subscribe(this.updateStore.bind(this))
  }

  downloadCSV() {
    var element = document.createElement("a");
    var file = new Blob([this.state.csv], {type: 'text/plain', charset: "utf-8"});
    element.href = URL.createObjectURL(file);
    element.download = "data.csv";
    element.click()
  }

  updateStore() {

    if(!this.state.csv) {
        this.setState({
          csv: true
        })
        var callback = (data) => {
            var csv = ""
            data.forEach((row) => {
                csv += row.join(", ")
                csv += "\n"
            })

            this.setState({
              csv: csv
            })
            this.downloadCSV()
        }

        this.props.game_data.loadFieldsWithUser(callback)
    }

  }

  componentWillUnmount() {
    this.unsubscribe()
  }

  render() {
    return (
        <div>
            <GameHeader game_data={this.props.game_data} handleError={this.props.handleError} />
            <div className="alert alert-info">{ t("csv_export_use") }</div>
            <pre>
            { this.state.csv }
            </pre>
        </div>
        )
  }
}