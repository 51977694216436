import React, { Component } from 'react';

import {lang} from "../Translate";
import scorezPath from "../Helpers";
import moment from "moment"

export default class GameLink extends Component {

    constructor(props) {
        super(props)

        this.state = {
            game: props.game
        }
    }

    renderGameDate(game_date)
    {
        var date = moment(game_date, "YYYY-MM-DD")
        date.locale(lang)
        var month = date.format("MMM")
        var day   = date.format("DD")
        var year  = date.format("YYYY")

        return (
          <span className="date">
            <span className="month">
            {month}
            </span>
            <span className="day">
            {day}
            </span>
            <span className="year">
            {year}
            </span>
          </span>
        )
    }

    render() {
        var game = this.state.game
        return (
            <div className="container game">
              <div className="row">
                <div className="col-xs">
                    { this.renderGameDate(game.date) }
                </div>
                <div className="col">
                    <div className="row">
                      <div className="col">
                        <a
                            className="gamelink-name"
                            href={scorezPath("/game/home/" + game.id)}>
                            {game.name}
                        </a>
                      </div>
                    </div>
                    <div className="row">
                    <div className="col-sm">
                        {game.location}
                    </div>
                    <div className="col-sm">
                        {game.organizer}
                    </div>
                    </div>
                </div>
              </div>
            </div>
        )
    }
}

export {GameLink};