import { TweenMax, TimelineMax } from 'gsap';
import AbstractAnimator from './AbstractAnimator'

export default class SortAnimator extends AbstractAnimator {

    constructor(props) {
        super(props)

        this.transition_time = 1
    }

    animate() {
        this.is_animating = true
        this.vars.tl = new TimelineMax({ delay: 1,
                                         repeat: 0,
                                         repeatDelay: 2,
                                         onUpdate:this.updateScore.bind(this)});

        // Initialise the temporary positions to the before position
        this.items
            .forEach((item) => {
                item.tempPosition = item.positionBefore
            })

        console.log(this.items)

        // Iterate all positions in reverse order
        // Shuffle the teams around one by one
        this.items
          .sort(this.sorting.reverseSortAfterTeamName)
          .forEach((item, index) =>
            {
                this.vars.tl.add(function(item){ item.updateCount = Date.now() }.bind(this, item))

                var yPos = this.columnLayoutY(item, item.positionAfter)
                this.vars.tl.add(function(item){ window.scrollTo(0, yPos) }.bind(this, item))

                this.vars.tl.add(TweenMax
                    .to(item.scoreitemElement, this.transition_time,
                        {
                            x: this.columnLayoutX(item, item.positionAfter),
                            y: yPos
                        }))


                this.items
                    .forEach((other_item, other_index) => {
                        if(other_item.tempPosition > item.tempPosition &&
                           other_item.tempPosition <= item.positionAfter) {
                            other_item.tempPosition -= 1
                            this.vars.tl.add(TweenMax
                                .to(other_item.scoreitemElement, this.transition_time,
                                    {
                                        x: this.columnLayoutX(other_item, other_item.tempPosition),
                                        y: this.columnLayoutY(other_item, other_item.tempPosition)
                                    }), "-="+this.transition_time)
                                    }
                    })

                item.tempPosition = item.positionAfter

            }
        )

        // Add delay at the end
        this.vars.tl.add(TweenMax.to(this, 5))
        this.vars.tl.add(function(item){
            this.is_animating = false
            this.has_animated = true
        }.bind(this))
    }
}