import { TweenMax, TimelineMax } from 'gsap';
import AbstractAnimator from './AbstractAnimator'

export default class CircleAnimator extends AbstractAnimator {

  constructor(props) {
    super(props)

    this.radius = 200; // Pixels
    this.transition_time1 = 2
    this.transition_time2 = 4
    this.steps = 50
    this.transition_time_int = 0.05
    this.transition_time = this.transition_time1 + this.transition_time2 + this.steps * this.transition_time_int
  }

  animate() {
    this.is_animating = true
    this.vars.tl = new TimelineMax({
      delay: 1,
      repeat: 0,
      repeatDelay: 2,
      onUpdate: this.updateScore.bind(this)
    });

    // Calc center
    let weightedCenter = this.items.reduce((acc, item) => {
      acc.x += this.columnLayoutX(item, item.positionAfter)
      acc.y += this.columnLayoutY(item, item.positionAfter)
      return acc
    }, { x: 0, y: 0 })

    weightedCenter.x /= this.items.length
    weightedCenter.y /= this.items.length

    let pi_ish = 3.1415
    this.vars.tl.add("StartPoint");

    // Find intersection circle / line(start pos, center of circle)
    this.items
      .sort(this.sorting.reverseSortAfterTeamName)
      .forEach((item, index) => {

        let xStart = this.columnLayoutX(item, item.positionBefore)
        let yStart = this.columnLayoutY(item, item.positionBefore)

        let dx = weightedCenter.x - xStart
        let dy = weightedCenter.y - yStart
        let dNorm = Math.sqrt(dx*dx + dy*dy)
        dx /= dNorm
        dy /= dNorm
        let dIntersectX = weightedCenter.x - dx * this.radius
        let dIntersectY = weightedCenter.y - dy * this.radius

        this.vars.tl.add(function (item) { item.updateCount = Date.now() }.bind(this, item),
          "StartPoint")

        this.vars.tl.add(TweenMax
          .to(item.scoreitemElement, this.transition_time1,
            {
              x: dIntersectX,
              y: dIntersectY,
              rotation: Math.atan2(dy, dx) * 180 / pi_ish - 90, // Perp. on line to center of circle
            }), "StartPoint")

      })


    for (let step = 0; step < this.steps; step += 1) {
      this.vars.tl.add("Intermediate" + step);

      this.items
        .sort(this.sorting.reverseSortAfterTeamName)
        .forEach((item, index) => {

          let xStart = this.columnLayoutX(item, item.positionBefore)
          let yStart = this.columnLayoutY(item, item.positionBefore)

          let dx = weightedCenter.x - xStart
          let dy = weightedCenter.y - yStart
          let dNorm = Math.sqrt(dx * dx + dy * dy)
          dx /= dNorm
          dy /= dNorm

          let prevRotation = Math.atan2(dy, dx) * 180 / pi_ish - 90

          let sinAngle = Math.sin(2*pi_ish * step / this.steps)
          let cosAngle = Math.cos(2*pi_ish * step / this.steps)

          let dxNext = dx * cosAngle - dy * sinAngle
          let dyNext = dx * sinAngle + dy * cosAngle

          let newRotation = Math.atan2(dyNext, dxNext) * 180 / pi_ish - 90

          if (prevRotation > newRotation) {
            newRotation += 360
          }

          let dIntersectX = weightedCenter.x - dxNext * this.radius
          let dIntersectY = weightedCenter.y - dyNext * this.radius

          this.vars.tl.add(TweenMax
            .to(item.scoreitemElement, this.transition_time_int,
              {
                x: dIntersectX,
                y: dIntersectY,
                rotation: newRotation, // Perp. on line to center of circle
              }), "Intermediate" + step)
        })
    }

    this.vars.tl.add("MidPoint");

    this.items
      .sort(this.sorting.sortAfterTeamName)
      .forEach((item, index) => {

        this.vars.tl.add(TweenMax
          .to(item.scoreitemElement, this.transition_time2,
            {
              x: this.columnLayoutX(item, item.positionAfter),
              y: this.columnLayoutY(item, item.positionAfter),
              rotation: 0,
            }), "MidPoint+=" + (0.05*index))
      })

    this.vars.tl.add(TweenMax.to(this, 5))
    this.vars.tl.add(function (item) {
      this.is_animating = false
      this.has_animated = true
    }.bind(this))
  }
}