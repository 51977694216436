import React, { Component } from 'react';
import { TweenLite } from 'gsap';
import t from '../Translate';

export default class AbstractAnimator extends Component {

  constructor(props) {
    super(props)

    console.log("Animator is created!")

    this.sorting = this.props.sorting
    this.items   = this.props.data

    this.vars = {
        height: 0,
        width: 0,
        itemHeight: 0, // Get from items
        itemWidth:  0, // Get from items
        itemMargin: 10,
        margin: 50,
        tl: null,
        itemPerCol: 1,
        xOffset: -20 // Compensate for left margin
    }

    this.transition_time = 3 // Seconds
    this.blink_time = 0.3 // Seconds

    this.maxTeamNameLength = 25
    this.longTeamNameFromEnd = 6

    this.show_before  = true
    this.is_animating = false
    this.has_animated = false

    this.initWidthHeight()

    this.load()
  }

  isOnCommand() {
    return false
  }

  initWidthHeight() {
    this.vars.height = window.innerHeight
    this.vars.width  = window.innerWidth
  }

  componentDidMount() {
    window.addEventListener("resize", this.updateDimensions.bind(this));
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions.bind(this));
  }

  updateScoreElement(item, i) {
    item.scoreElement.innerText = Math.round((1-i)*item.scoreBefore + i*item.scoreAfter)
  }

  updatePositionElement(item, i) {
    var position = (1-i)*item.logicalPositionBefore + i*item.logicalPositionAfter
    item.positionElement.innerText = Math.round(position)
  }

  updatePositionChangeOpacity(item, i) {
    if(item.scoreBefore > 0)
    {
      item.positionChangeElement.style.opacity = i
    }
  }

  updateScoreDeltaOpacity(item, i) {
    if(item.scoreBefore > 0)
    {
      item.scoreDeltaElement.style.opacity = i
    }
  }

  updateScoreItemElementColor(item, raw_i) {
    var blink_i = this.blink_time/this.transition_time
    if(raw_i >= 1 && raw_i < 1 + blink_i) {
      item.scoreitemElement.style.color = "#ba4900"
    } else if(raw_i >= 1 + blink_i && raw_i < 1 + 4*blink_i) {
      item.scoreitemElement.style.color = "black"
    }
  }

  updateScore() {
    var now = Date.now();

    this.items
      .forEach((item) => {
        var raw_i = (now-item.updateCount)/(this.transition_time*1000)
        var i = Math.max(0, Math.min(1, raw_i))
        if(item.updateCount < 0)
        {
            i = 0
        }

        this.updateScoreElement(item, i)
        this.updatePositionElement(item, i)
        this.updatePositionChangeOpacity(item, i)
        this.updateScoreDeltaOpacity(item, i)

        this.updateScoreItemElementColor(item, raw_i)
    })
  }

  load()
  {
    var items = this.items

    this.has_animated = false

    items.forEach((item) => item.updateCount = -1)

    this.initWidthHeight()

    items.sort(this.sorting.sortBeforeTeamName)
      .forEach((item, index) => {
          var prev_index = index
          while(prev_index >= 0 && this.sorting.sortBefore(item, items[prev_index]) === 0)
          {
            prev_index -= 1
          }

          if(prev_index !== index) {
            prev_index += 1
          }

          item.positionBefore = index + 1
          item.logicalPositionBefore = prev_index + 1
        })

    items.sort(this.sorting.sortAfterTeamName)
      .forEach((item, index) => {
          var prev_index = index - 1
          while(prev_index >= 0 && this.sorting.sortAfter(item, items[prev_index]) === 0)
          {
            prev_index -= 1
          }

          if(prev_index !== index) {
            prev_index += 1
          }

          item.positionAfter = index + 1
          item.logicalPositionAfter = prev_index + 1
        })
  }

  itemsPerColumn() {
    var itemWithMargin = this.vars.itemWidth + this.vars.itemMargin
    var height = (window.innerHeight - this.vars.margin)
    var itemsPerCol = height / this.vars.itemHeight
    var nbCols = Math.ceil(this.items.length / itemsPerCol)

    while (nbCols * itemWithMargin > window.innerWidth && nbCols > 1) {
      nbCols--
    }

    return Math.ceil(this.items.length / nbCols)
  }

  columnLayoutX(item, position)
  {
    var itemWithMargin = this.vars.itemWidth + this.vars.itemMargin
    var height = (window.innerHeight-this.vars.margin)
    var itemsPerCol = height/this.vars.itemHeight
    var nbCols = Math.ceil(this.items.length/itemsPerCol)

    while(nbCols*itemWithMargin > window.innerWidth && nbCols > 1)
    {
        nbCols--
    }

    itemsPerCol = Math.ceil(this.items.length / nbCols)

    var totalWidth = nbCols*itemWithMargin - this.vars.itemMargin
    var leftX = (window.innerWidth/2) - totalWidth/2
    var column = Math.floor((position-1)/itemsPerCol)

    return leftX + itemWithMargin*column + this.vars.xOffset
  }

  columnLayoutY(item, position)
  {
    var itemWithMargin = this.vars.itemWidth + this.vars.itemMargin
    var height = (window.innerHeight-this.vars.margin)
    var itemsPerCol = height/this.vars.itemHeight
    var nbCols = Math.ceil(this.items.length/itemsPerCol)

    while(nbCols*itemWithMargin > window.innerWidth && nbCols > 1)
    {
        nbCols--
    }

    itemsPerCol = Math.ceil(this.items.length / nbCols)

    var row = (position-1)%itemsPerCol
    return row*this.vars.itemHeight
  }

  updateDimensions() {
    // Do not adjust while animating
    if(this.is_animating) {
        return
    }

    // Do not show before score
    if(!this.has_animated && !this.show_before) {
        return
    }

    this.initWidthHeight()

    this.items
      .forEach((item, index) =>
        {
          var position = this.has_animated?item.positionAfter: item.positionBefore
          TweenLite
            .set(item.scoreitemElement,
                {
                  visibility: "visible",
                  x: this.columnLayoutX(item, position),
                  y: this.columnLayoutY(item, position)
                })
      }
    )

    this.items
      .forEach((item) => {
        if(item.scoreitemElement) {
          var ref = item.scoreitemElement
          this.vars.itemWidth  = ref.offsetWidth
          this.vars.itemHeight = ref.offsetHeight + this.vars.itemMargin
        }
      })
  }

  getTeamName(item) {
    // + 2 to account for the two dots
    if(item.team.length > this.maxTeamNameLength+2)
    {
      var l = item.team.length
      return item.team.substring(0, this.maxTeamNameLength - this.longTeamNameFromEnd)
      + ".." + item.team.substring(l - this.longTeamNameFromEnd, l)
    }
    return item.team
  }

  updateScoreitemElementRef(item, ref) {
    item.scoreitemElement = ref

    if(ref) {
      this.vars.itemWidth  = ref.offsetWidth
      this.vars.itemHeight = ref.offsetHeight + this.vars.itemMargin

      var position = this.has_animated?item.positionAfter: item.positionBefore

      TweenLite
      .set(ref,
          {
              visibility: (this.show_before?"visible":"hidden"),
              x: this.columnLayoutX(item, position),
              y: this.columnLayoutY(item, position)
          })
    }
  }

  updateMedalElementRef(item, ref) {
    item.medalElement = ref
  }

  updatePositionElementRef(item, ref) {
    item.positionElement = ref

    if(ref) {
      this.updatePositionElement(item, 0)
    }
  }

  updateScoreDeltaElementRef(item, ref) {
    item.scoreDeltaElement = ref

    if(ref) {
      item.scoreDeltaElement = ref
      item.scoreDeltaElement.style.opacity = 0
      item.scoreDeltaElement.innerText = Math.floor(item.scoreAfter - item.scoreBefore)
    }
  }

  updatePositionChangeElementRef(item, ref) {
    item.positionChangeElement = ref

    if(ref) {
      item.positionChangeElement.classList.remove("scoreboard-change-up")
      item.positionChangeElement.classList.remove("scoreboard-change-down")

      if(item.positionAfter > item.positionBefore)
      {
          item.positionChangeElement.classList.add("scoreboard-change-down")
      }

      if(item.positionBefore > item.positionAfter)
      {
          item.positionChangeElement.classList.add("scoreboard-change-up")
      }
      item.positionChangeElement.style.opacity = 0

      this.updatePositionChangeOpacity(item, 0)
    }
  }

  handleItemClick(event) {
    if (this.is_animating) {
      this.animateNext()
    }
  }

  animateNext() {
    // Placeholder, override in subclass
  }

  showMedals() {
    this.vars.tl.add("End")
    this.items.forEach((item) => {
      this.vars.tl.to(item.medalElement, 1, {
        opacity: 1
      }, "End-=5")
    })
  }

  render() {
    if(this.props.animate && !this.is_animating) {
      setTimeout(() => {
        this.animate();
        this.showMedals();
      }, 1000)
    }

    return (
      <div className="wrap-div" onKeyPress={this.animateNext.bind(this)}>
        { this.isOnCommand() &&
          <div id="bottom-scoreboard"
               onClick={this.handleItemClick.bind(this)}
          >
          {t("animate_next")}
          </div>
        }
        { this.items
          .sort(this.sorting.sortAfterTeamName)
          .map((item, index) =>
              <div className="scoreitem"
                   ref={(input) => this.updateScoreitemElementRef(item, input)}
                   style={{"cursor": "pointer"}}
              key={"score-" + index}>
                <div className="scoreboard-team">
                  <span className="badge scoreitem-pos">
                    <span ref={(input) => this.updatePositionElementRef(item, input)}
                          >{index + 1}</span>
                  </span>
                  <span ref={"team_" + item.id} className="scoreitem-team">{this.getTeamName(item)}</span>
                </div>
                <div ref={(input) => this.updateMedalElementRef(item, input)}
                  className="scoreboard-medals">
                  { item.medals && item.medals.map((round) => {
                      return (<span key={item.team+"-span-"+round} className="scoreboard-tooltip">
                        <img src="/star.svg" alt="medal" height="10px" />
                        <div className="scoreboard-tooltip-text">
                          {t("highest_score_on_round")} {round}
                        </div>
                      </span>)
                    })
                  }
                </div>
                <div className="scoreboard-delta">
                  <div ref={(input) => this.updatePositionChangeElementRef(item, input)}
                        className="scoreboard-change"></div>
                  <div ref={(input) => this.updateScoreDeltaElementRef(item, input)}
                        className="scoreboard-delta-score"></div>
                </div>
                <div ref={(input) => item.scoreElement = input}
                      className="scoreboard-score">
                {Math.floor(item.scoreBefore)}
                </div>
              </div>
          )
        }
      </div>
    )
  }
}