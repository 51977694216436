const initialState = {
    user: null,
    role: null
}

export function scorezReducer(state=initialState, action) {
  switch (action.type) {
    case 'LOG_IN':
      return {...state,
              user: action.user}
    case 'LOG_OUT':
      return {...state,
              user: null}
    case 'ROLE':
      if(action.role !== state.role)
      {
        return {...state,
                role: action.role}
      }
      return state
    default:
      return state
  }
}
