import React, { Component } from 'react';
import Superagent from 'superagent';
import './GameSheet.css';

import GameHeader from './GameHeader';
import { isRoundUsedForTieBreaker, Sorting } from './Sorting';

import t from "../Translate";

import 'react-contexify/dist/ReactContexify.min.css'
import { ContextMenuProvider, ContextMenu, Item } from 'react-contexify';
var FontAwesome = require('react-fontawesome');


class GameSheet extends Component {

  constructor(props) {
    super(props);

    this.state = {
        fields_route: window.apiUrl + "/scorez-api/fields.php",
        data: false,
        exaequo_rules: false,
        edit: false,
        prevValueCurrentEdit: "",
        editWidth: false,
        editHeight: false,
        user: null,
        is_owner: false,
        sortColumn: false,
        edit_locked: false
    };

    this.game_data = props.game_data
    this.handleError  = props.handleError;

    this.max_per_round = []
    this.tie_breaker_diffs_per_round = []
    this.totals = []

    // For each row (key) give the next row (value) in the current sorting
    this.next_row = []

    // Working variable
    this.row_counter = 0

    this.sorting = new Sorting()
  }

  componentDidMount() {
    this.updateStore()
    this.unsubscribe = window.store.subscribe(this.updateStore.bind(this))
  }

  updateStore() {
    var user = window.store.getState().user
    var role = window.store.getState().role

    // Is the user actually used anywhere while rendering?
    // Shouldn't we remove the user from the state?
    // We should, only role matters
    this.setState({
      user,
      is_owner: role === "owner"
    })

    this.loadFields(user)
    this.loadExAequoRules()
  }

  componentWillUnmount() {
    this.unsubscribe()
  }

  loadExAequoRules() {
    this.game_data.getExAequoRules((rules) => {

      this.sorting.setExAequoRules(rules)
      this.updateData(this.state.data, rules)
      this.setState({
        exaequo_rules: rules
      })
    })
  }

  loadFields(user) {

    window.identity.getToken(
      /* Success callback */
      (idToken) => {
        this.game_data.loadFields(idToken, (data) => {
          this.updateData(data, this.state.exaequo_rules)
          this.setState({
            data
          })
        })
      },
      /* Failure callback */
      () => {
        this.game_data.loadGame((game) => {
          if(game.public === 1)
          {
            this.game_data.loadFields(null, (data) => {
              this.updateData(data, this.state.exaequo_rules)
              this.setState({
                data
              })
            })
          } else {
            // Wait for the auth provider to realise a user is logged in
            // Don't show anything to the user?
          }
        })
      }
    )
  }

  getDiffForExAequoRule(value, colNb, exaequo_rules) {
    var answer = 0
    for(var i = 0; i < exaequo_rules.length; i++) {
      var rule = exaequo_rules[i]
      if(rule.rule === "tie-breaker" && rule.round === colNb)
      {
        answer = rule.answer
        break;
      }
    }

    return Math.abs(value-answer)
  }

  updateData(data, exaequo_rules) {
    if(data && exaequo_rules) {

      // Calculate max. score per round
      this.max_per_round = []

      data.forEach((row, rowNb) => {
        if(rowNb !== 0) {
          row.forEach((value, colNb) => {
            if(colNb !== 0) {
              if (isRoundUsedForTieBreaker(exaequo_rules, colNb) && !isNaN(value)) {

                var diff = this.getDiffForExAequoRule(value, colNb, exaequo_rules)
                if(!this.tie_breaker_diffs_per_round[colNb])
                {
                  this.tie_breaker_diffs_per_round[colNb] = []
                }
                this.tie_breaker_diffs_per_round[colNb][rowNb] = diff

                if(this.max_per_round[colNb] && !isNaN(diff)) {
                  this.max_per_round[colNb] = Math.min(this.max_per_round[colNb], diff)
                } else if (!isNaN(diff)) {
                  this.max_per_round[colNb] = diff
                }

              } else {

                if(this.max_per_round[colNb] && !isNaN(value)) {
                  this.max_per_round[colNb] = Math.max(this.max_per_round[colNb], value)
                } else if (!isNaN(value)) {
                  this.max_per_round[colNb] = value
                }

              }
            }
          })
        }
      })

      // Calculate total scores per team
      this.totals = data.map((row, rowNb) => {
        return this.sumRow(row, exaequo_rules)
      })
    }
  }

  addTeam()
  {
    var modifiedData = [].concat(this.state.data);
    modifiedData.push(
      // For every existing (non-deleted) round, set ""
      modifiedData[0].map((item) => "")
    )

    // Make sure something is filled in in the team column
    modifiedData[modifiedData.length-1][0] = ""

    this.setState({
        data: modifiedData
    })
  }

  addRound()
  {
    var data = [].concat(this.state.data)
    data.forEach((row, index) => {
      data[index].push("")
    })
    var colNb = data[0].length-1
    if(colNb === 0) // No rounds existed
    {
      colNb = 1
    }
    this.handleCellEdit(0,  colNb, t("round") + " " + colNb)

    this.setState({
      data: data
    })
  }

  removeRound(colNb, roundName) {
    console.log("Remove round " + colNb)
    if(!window.confirm(t("remove_round_confirmation") + roundName + "?"))
    {
      return
    }

    window.identity.getToken(function(idToken) {
      var post_data = {
        "game_id": this.game_data.getGameId(),
        "col": colNb,
        "user": idToken,
      }

      this.game_data.removeFields(post_data, () => {
        this.loadFields(this.state.user)
      })
    }.bind(this))
  }

  removeTeam(rowNb, teamName) {
    console.log("Remove team " + rowNb)
    if(!window.confirm(t("remove_team_confirmation") + teamName + "?"))
    {
      return
    }

    window.identity.getToken(function(idToken) {
      var post_data = {
        "game_id": this.game_data.getGameId(),
        "row": rowNb,
        "user": idToken,
      }

      this.game_data.removeFields(post_data, () => {
        this.loadFields(this.state.user)
      })
    }.bind(this))
  }

  editCell(rowNb, colNb)
  {
    if(!this.canEdit()) {
      return;
    }

    if(rowNb === 0 && colNb === 0) {
      return;
    }

    var modifiedData = [].concat(this.state.data);
    if(this.state.edit)
    {
      modifiedData[this.state.edit[0]][this.state.edit[1]] = this.state.prevValueCurrentEdit
    }

    this.setState({
      data: modifiedData,
      prevValueCurrentEdit: modifiedData[rowNb][colNb],
      edit: [rowNb, colNb],
      editWidth: this.refs["field-" + rowNb + "-" + colNb].offsetWidth,
      editHeight: this.refs["field-" + rowNb + "-" + colNb].offsetHeight
    })
  }

  handleCellEdit(rowNb, colNb, value)
  {
    if(!this.state.user) {
      this.handleError(t("not_logged_in"), true, null)
      return
    }

    var data = [].concat(this.state.data)

    var changed = false;

    if(rowNb > 0 && colNb > 0)
    {
      if(isNaN(value))
      {
        this.handleError(t("error_not_a_number"), true, null);
        return;
      }
      else
      {
        changed = isNaN(parseFloat(data[rowNb][colNb]))
                || Math.abs(parseFloat(data[rowNb][colNb]) - parseFloat(value)) > Number.EPSILON;
      }
    }
    else
    {
      changed = String(data[rowNb][colNb]) !== value
    }

    //debug changed = false;

    if(!changed)
    {
      console.log("Nothing changed")
      // Nothing changed, don't push it to the database
      this.setState({
        edit: false,
      })
      return
    }

    window.identity.getToken(function(idToken) {
        Superagent
          .post(this.state.fields_route)
          .send({"game_id": this.game_data.getGameId(), "row": rowNb, "col": colNb, "value": value, "user": idToken})
          .set('Accept', 'application/json')
          .end(function(err,res){
            //console.log(err);
            //console.log(res);
            if(!this.handleError(t("error_updating_field"), err, res))
            {

              data[rowNb][colNb] = value

              // Make sure cell (0,0) does not exist
              delete data[0][0];

              if(this.state.edit
                  && this.state.edit[0] === rowNb
                  && this.state.edit[1] === colNb )
              {
                this.setState({
                  edit: false,
                  data: data
                })
              }
              else
              {
                // Another edit operation was triggered
                // Retrieve new data and update accepted value of this cell
                // Do not change the edit state
                data = [].concat(this.state.data)
                data[rowNb][colNb] = value

                this.setState({
                  data: data
                })
              }
            }
          }.bind(this));
      }.bind(this))
  }

  handleCellBlur(rowNb, colNb)
  {
    this.handleCellEdit(rowNb, colNb, this.refs.edit.value)
  }

  handleCellKeyPress(rowNb, colNb, event)
  {
    if(event.key === 'Enter')
    {
      this.handleCellEdit(rowNb, colNb, this.refs.edit.value)

      // Start editing the next cell
      if(this.next_row[rowNb]) {
        this.editCell(this.next_row[rowNb], colNb)
      }
    }
  }

  getCellWidth(colNb) {
    return (colNb === 0)? "200px": "80px";
  }

  /* Column compare: used for sorting of the table
   *
   * sortColumn not set is not sorted, use row number are comparison operator
   * Teams are alphabetically sorted (sortColumn is 0)
   * Tie breaker rounds use the absolute difference w.r.t. the answer
   *
   * When the comparison operator yields 0, ex-aequo rules are applied
   */
  compareColumn(aRowNb, bRowNb) {
    aRowNb = parseInt(aRowNb, 10)
    bRowNb = parseInt(bRowNb, 10)

    // Make sure the first row is on top
    if(aRowNb === 0) {
      return -1
    }

    if(bRowNb === 0) {
      return 1
    }

    if(this.state.sortColumn === null) {
      return aRowNb - bRowNb
    } else if(this.state.sortColumn === 0) {
      return this.state.sortDirection*this.state.data[aRowNb][0].localeCompare(this.state.data[bRowNb][0])
    }

    var cmp = 0
    if(this.state.sortColumn === "total") {
      cmp = this.state.sortDirection*(this.totals[aRowNb] - this.totals[bRowNb])
    } else if(isRoundUsedForTieBreaker(this.state.exaequo_rules, this.state.sortColumn)) {
      cmp = this.state.sortDirection*
             (this.tie_breaker_diffs_per_round[this.state.sortColumn][aRowNb]
             - this.tie_breaker_diffs_per_round[this.state.sortColumn][bRowNb])
    } else {
      cmp = this.state.sortDirection*(this.state.data[aRowNb][this.state.sortColumn]
              - this.state.data[bRowNb][this.state.sortColumn])
    }

    // TODO Incorrect logic here when sorting top-bot, bot-top
    // Sorting by tie breaker round will apply incorrect ex-aequo order
    //  min difference counts, not max score
    // Not too problematic
    if(cmp === 0) {
      return -this.state.sortDirection*this.sorting.applyExAequoRulesAndTeamName(
            {
              team: this.state.data[aRowNb][0],
              row: this.state.data[aRowNb]
            },
            {
              team: this.state.data[bRowNb][0],
              row: this.state.data[bRowNb]
            },
            this.state.data[0].length) // TODO check
    }

    return cmp
  }

  sortColumn(colNb, direction) {
    if(colNb === this.state.sortColumn && direction === this.state.sortDirection) {
      this.setState({
        sortColumn: false
      })
    } else {
      this.setState({
        sortColumn: colNb,
        sortDirection: direction
      })
    }
  }

  getSortElements(colNb) {
    var inc_sort_class = "sort-column"
    var dec_sort_class = "sort-column"

    if(this.state.sortColumn !== null && this.state.sortColumn === colNb) {
      if(this.state.sortDirection === 1) {
        inc_sort_class += " selected-sort-column"
      } else {
        dec_sort_class += " selected-sort-column"
      }
    }

    return (
        <div className="sort-column-container">
          <div className={inc_sort_class}
               onClick={this.sortColumn.bind(this, colNb, 1)}
          >
            &#9650;
          </div>
          <div className={dec_sort_class}
               onClick={this.sortColumn.bind(this, colNb, -1)}
          >
            &#9660;
          </div>
        </div>
      )
  }

  getHeaderCell(colNb, value) {
    var headerClass = "gamesheet-header"
    // Don't allow deletion of first column
    var removeRoundContext = colNb !== 0;
    var width = this.getCellWidth(colNb)
    return (
      <th className={headerClass}
          ref={"field-0-" + colNb}
          key={"field-0-" + colNb}
          style={{maxWidth: width, minWidth: width}}
          >
          <div onClick={this.editCell.bind(this, 0, colNb)}>
            {  removeRoundContext &&
              <div>
                { this.canEdit() && <ContextMenu id={"contextmenu-" + colNb}>
                    <Item
                         key="remove-round"
                         onClick={this.removeRound.bind(this, colNb, value)}>
                        <FontAwesome name="list" /> { t("remove") } {value}
                    </Item>
                </ContextMenu> }
                <ContextMenuProvider className="contextmenu" id={"contextmenu-" + colNb}>
                  <div>
                    <div className="contextmenu-round-div"><span>{value}</span></div>
                  </div>
                </ContextMenuProvider>
              </div>
            }
            { !removeRoundContext &&
              <div><span>{value}</span></div>
            }
          </div>
          { this.getSortElements(colNb) }
      </th>
      )
  }

  getCell(rowNb, colNb, value)
  {
    var width = this.getCellWidth(colNb)
    if (this.state.edit && this.state.edit[0] === rowNb &&
        this.state.edit[1] === colNb)
    {
        return (
          <td className="gamesheet-edit"
              key={"fieldedit-" + rowNb + "-" + colNb}>
            <input className="gamesheet-input" type="text"
                autoFocus
                ref="edit"
                key={"edit-" + rowNb + "-" + colNb}
                onKeyPress={this.handleCellKeyPress.bind(this, rowNb, colNb)}
                onBlur={this.handleCellBlur.bind(this, rowNb, colNb)}
                style={{width: this.state.editWidth, height: this.state.editHeight}}
                defaultValue={value}
            />
          </td>
        )
    }
    else if (rowNb === 0) {
      return this.getHeaderCell(colNb, value)
    } else {
      var removeTeamContext = colNb === 0;

      var cell_class = "gamesheet-row"
      if(this.row_counter%2 === 0) {
        cell_class += " gamesheet-even-row"
      }

      if(isRoundUsedForTieBreaker(this.state.exaequo_rules, colNb)) {
        cell_class += " gamesheet-tiebreaker"
      }

      if(colNb === 0) {
        cell_class += " gamesheet-team"
      } else {
        cell_class += " gamesheet-score"
      }

      if(!isRoundUsedForTieBreaker(this.state.exaequo_rules, colNb)
          && colNb !== 0
          && this.max_per_round[colNb]
          && Math.abs(this.max_per_round[colNb] - parseFloat(value)) < Number.EPSILON) {
        cell_class += " gamesheet-maxscore"
      }

      if(isRoundUsedForTieBreaker(this.state.exaequo_rules, colNb)
         && this.max_per_round[colNb]) {

        var diff = this.tie_breaker_diffs_per_round[colNb][rowNb]
        if(Math.abs(this.max_per_round[colNb] - diff) < Number.EPSILON) {
          cell_class += " gamesheet-maxscore"
        }
      }

      return (
        <td className={cell_class}
            onClick={this.editCell.bind(this, rowNb, colNb)}
            ref={"field-" + rowNb + "-" + colNb}
            key={"field-" + rowNb + "-" + colNb}
            style={{maxWidth: width, minWidth: width}}
            >
            {  removeTeamContext &&
              <div>
                { this.canEdit() && <ContextMenu id={"contextmenu-team-" + rowNb}>
                    <Item
                         key="remove-team"
                         onClick={this.removeTeam.bind(this, rowNb, value)}>
                        <FontAwesome name="list" /> { t("remove") } {value}
                    </Item>
                </ContextMenu> }
                <ContextMenuProvider className="contextmenu" id={"contextmenu-team-" + rowNb}>
                  <div className="contextmenu-team-div">{value}</div>
                </ContextMenuProvider>
              </div>
            }
            { !removeTeamContext && value }
            </td>
        )
    }
  }

  sumRow(row, exaequo_rules) {
      var sum = row.map((value, colNb) => {
        if(isRoundUsedForTieBreaker(exaequo_rules, colNb)) {
          return 0
        } else if(colNb > 0) {
          var floatValue = parseFloat(value)
          if(!isNaN(floatValue)) {
            return floatValue
          }
        }
        return 0
      }).reduce((a, b) => a+b, 0)

      return sum
  }

  getSumRow(row, rowNb) {
    if(rowNb === 0) {
      return (
          <th className="gamesheet-total">
          { t("total") }
          { this.getSortElements("total") }
          </th>
        )
    } else {
      var cell_class = "gamesheet-row"
      if(this.row_counter%2 === 0) {
        cell_class += " gamesheet-even-row"
      }
      cell_class += " gamesheet-total-row"

      return (
          <td className={cell_class}>
          {this.totals[rowNb]}
          </td>
        )
    }
  }

  getTeamHeaderCell() {
    return (
        <th className="gamesheet-header">
          <div className="gamesheet-teams-header">{ t("teams") }</div>
          { this.getSortElements(0) }
        </th>
      )
  }

  getRow(row, rowNb) {
    this.row_counter += 1

    // Class for index column
    var cell_class = ""
    if(this.row_counter > 1) {
      cell_class += "gamesheet-row"
      if(this.row_counter%2 === 0) {
        cell_class += " gamesheet-even-row"
      }
    }

    var row_class = "gamesheet-view-row"
    if(this.canEdit()) {
      row_class = "gamesheet-edit-row"
    }

    return (
      <tr key={"row-" + rowNb} className={row_class}>
        <td className={cell_class + " gamesheet-index"}>{this.row_counter > 1?this.row_counter-1:""}</td>
        { rowNb === 0 && this.getTeamHeaderCell() }
        {/*
          This hacky code exists because of a change in data representation
          The first cell used to be removed, but this caused problems for the GameHome page
        */}
        { rowNb === 0 && row.slice(1).map((value, colNb) => this.getCell(rowNb, colNb+1, value)) }
        { rowNb > 0 && row.map((value, colNb) => this.getCell(rowNb, colNb, value)) }
        { this.getSumRow(row, rowNb) }
      </tr>
    )
  }

  getCreationButtons() {
    return (
        <div className="btn-group">
          <button key="create-team"
                  className="btn btn-primary"
                  onClick={this.addTeam.bind(this)}>
                  { t("add_team") }</button>
          <button key="create-round"
                  className="btn btn-primary"
                  onClick={this.addRound.bind(this)}>
                  { t("add_round") }</button>
          <button key="lock-edit"
            className="btn btn-primary"
            onClick={this.lockEdit.bind(this)}>
            { this.state.edit_locked?t("edit_mode"):t("display_mode") }</button>

        </div>
      )
  }

  lockEdit() {
    this.setState({
      edit_locked: !this.state.edit_locked
    })
  }

  canEdit() {
    return this.state.is_owner && !this.state.edit_locked
  }

  showEditButton() {
    return this.state.is_owner && this.state.edit_locked
  }

  getTable() {
    var prev_row = null
    this.next_row = []

    return (
      <div className="gamesheet-container">
      <div className="gamesheet-table-container">
      { this.canEdit() && this.getCreationButtons() }
      <table className="gamesheet-table">
      <tbody>
      { Object.keys(this.state.data)
        .sort(this.compareColumn.bind(this))
        .map((rowNb) => {

          // Create next_row data structure
          if(prev_row) {
            this.next_row[prev_row] = parseInt(rowNb, 10)
          }
          prev_row = parseInt(rowNb, 10)

          return this.getRow(this.state.data[rowNb], parseInt(rowNb, 10))
        }) }
      </tbody>
      </table>
      { this.canEdit() && this.getCreationButtons() }
      </div>
      </div>
      )
  }

  dataReady() {
    return this.state.data && this.state.exaequo_rules
  }

  render() {
    this.updateData(this.state.data, this.state.exaequo_rules)

    this.row_counter = 0

    return (
      <div>
        <GameHeader game_data={this.game_data} handleError={this.props.handleError} />
          {this.showEditButton() &&
            <button 
              className="btn btn-primary"
              onClick={() => this.setState({edit_locked: false})}>{t("edit")}</button>
          }
          {this.dataReady() && this.getTable()}
          {!this.dataReady() && <p>Loading..</p>}
      </div>
    );
  }
}

export default GameSheet;
