import React, { Component } from 'react';
import Superagent from 'superagent';
import CookieConsent, { Cookies } from "react-cookie-consent";
import Title from './Title';
import Login from './Login';
import Nav from './Nav';
import Footer from './Footer';
import {GameLink} from './game/GameLink';
import moment from 'moment';

import {t} from './Translate';
import scorezPath from "./Helpers";

export default class Home extends Component {

  constructor(props) {
    super(props);

    this.state = {
      dataRoute: window.apiUrl + "/scorez-api/games.php",
      error_msg: false,
      data: false,
      user_games: false,
      user: null
    }

    this.handleError = this.props.handleError;
  }

  componentDidMount() {
    this.unsubscribe = window.store.subscribe(this.updateStore.bind(this))
    this.updateStore()
    this.getRecentScorez(2);
  }

  componentWillUnmount() {
    this.unsubscribe()
  }

  getRecentScorez(nb_days_in_past)
  {
    var from = moment().subtract(nb_days_in_past, 'days').format("YYYY-MM-DD")
    var to = moment().format("YYYY-MM-DD")
    Superagent
      .post(this.state.dataRoute)
      .send({start_date: from, end_date: to})
      .set('Accept', 'application/json')
      .end(function(err,res){
        if (!this.handleError("Error fetching games", err, res)) {
          if ((!res.body || res.body.length === 0) && nb_days_in_past < 30)
          {
            this.getRecentScorez(nb_days_in_past + 5)
          } else {
            this.setState({
                data: res.body
            });
          }
        }
      }.bind(this));
  }

  updateStore() {
    if (!window.store.getState())
    {
      return;
    }

    var user = window.store.getState().user

    this.setState({
        user: user,
        user_games: false,
    })

    window.identity.getToken(
        /* Success callback */
      (idToken) => {
        Superagent
          .post(this.state.dataRoute)
          .send({user: idToken})
          .set('Accept', 'application/json')
          .end(function(err,res){
            console.log(err)
            if (!this.handleError("Error fetching games", err, res)) {
                this.setState({
                    user_games: res.body
                });
            }
          }.bind(this))
        })
  }

  responseGoogle(response) {
    console.log(response)
  }

  render() {

    return (
        <div id="home"
             class="d-flex flex-column h-100">
          <Title />
          <Nav />

          { !this.state.user &&
            <div className="scorez-content">
              <h2>{t("about")}</h2>
              <p>{t("scorez_is")}</p>
              <p>{t("scorez_how")}</p>
              <iframe title="Scorez" width="100%" height="300px" src="https://www.youtube.com/embed/OvAUU2Vl64g" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
              <img alt="example" src="/scorez/example_2.png" max-width="400px" className="img-fluid img-thumbnail" />
              <br />
              <p style={{"max-width": "200px", "padding-top": "10px"}}>{t("log_in_to_get_started")}
              <Login ref="login" />
              </p>
              <div style={{clear:"both"}} />
            </div>
          }
          {/* { this.state.user && <CreateGame handleError={this.handleError} /> }
          { this.state.user_games &&
            <div className="games">
              <h2>{t("latest_user_scorez")}</h2>
              <GameLinkHeader />
              {
                this.state.user_games
                .sort((a,b) => a.date.localeCompare(b.date))
                .map((game) =>
                  <GameLink key={game.id} game={game} />
                )
              }
            </div>
          } */}

          { this.state.user && <div id="create" className="scorez-content">
            <h2>{t("create_game")}</h2>
            <p>{t("create_game_explanation")}</p>
            <button onClick={ () => window.location.href = scorezPath("/create")} className="action-btn btn">{ t("create_game") }</button>
            </div>
          }

          <div className="scorez-content buy-code">
            <h2>{t("buy_game_codes")}</h2>
            <p>{ t("buy_code_explanation") }</p>
            <button onClick={ () => window.location.href = '/purchase.html'} className="action-btn btn">{ t("buy_codes") }</button>
          </div>

          <div className="games">
            <h2>{t("latest_public_scorez")}</h2>
            { this.state.data &&
              this.state.data
              .sort((a,b) => a.date.localeCompare(b.date))
              .map((game) =>
                <GameLink key={game.id} game={game} />
              )
            }
            { !this.state.data &&
            <div className="alert alert-warning">{t("no_scorez")}</div>
            }
          </div>
          <div style={{clear:"both"}} />
          <Footer />
          <CookieConsent
            enableDeclineButton
            location="bottom"
            buttonText="Accept"
            cookieName="scorez-cookie"
            style={{ background: "#2B373B" }}
            expires={150}
            onDecline={() => {
              Cookies.remove('_ga');
              Cookies.remove('_gat_gtag_UA_4207086_3');
              Cookies.remove('_gid');
              Cookies.remove('sc');
             }}
          >
          This website uses cookies, but they are optional. Do you accept these cookies?
          </CookieConsent>
        </div>
      )
  }
}