import React, { Component } from 'react';
import logo from './logo.svg';
import Login from './Login';
// import {t, lang} from './Translate';

export default class Nav extends Component
{
    render()
    {
        return (
          <div className="title-outer">
            <a href="/">
              <img alt="logo" src={logo} className="title-logo"/>
              <h1 className="title">Scorez</h1>
            </a>
            <Login ref="login" />
            <div style={{clear: "both"}} />
          </div>
        )
    }
};