import React, { Component } from 'react';
import Superagent from 'superagent';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import './BrowseGames.css';

import Title from '../Title';
import Nav from '../Nav';
import Footer from '../Footer';
import {GameLink} from './GameLink';
import moment from 'moment';

import {t} from '../Translate';

export default class BrowseGames extends Component {

  constructor(props) {
    super(props);

    this.state = {
      dataRoute: window.apiUrl + "/scorez-api/games.php",
      error_msg: false,
      data: false,
      date: moment(),
      user: null
    }

    this.nb_weeks = 2;
    this.handleError = this.props.handleError;
  }

  componentDidMount() {
    this.getScorez(this.state.date.clone());
  }

  getScorez(date)
  {
    var from = date.clone().subtract(this.nb_weeks, "weeks").format("YYYY-MM-DD")
    var to   = date.format("YYYY-MM-DD")

    window.identity.getToken(
      (idToken) => {
        this.getScorezImpl({
          start_date: from,
          end_date: to,
          user: idToken
        })
      },
      () => {
        this.getScorezImpl({
          start_date: from,
          end_date: to
        })
      }
    );

  }

  getScorezImpl(postData)
  {
    Superagent
      .post(this.state.dataRoute)
      .send(postData)
      .set('Accept', 'application/json')
      .end(function(err,res){
        if (!this.handleError("Error fetching games", err, res)) {
          console.log(res)
            if ((!res.body || res.body.length < 20) && this.nb_weeks < 8)
            {
                this.nb_weeks += 2;
                this.getScorez(this.state.date.clone())
            }
            else
            {
                this.setState({
                    data: res.body
                });
            }
        }
      }.bind(this));
  }

  handleDateChange(date)
  {
    this.setState({
      date: date
    });

    this.nb_weeks = 2
    this.getScorez(date.clone())
  }

  render() {
    return (
        <div id="my_games" class="d-flex flex-column h-100">
          <Title />
          <Nav />
          <div className="games">
            <h2>{t("list_scorez")}</h2>
            <div className="date_picker">
                <b>{t("date")} </b><DatePicker
                        selected={this.state.date}
                        onChange={this.handleDateChange.bind(this)}
                        dateFormat="YYYY-MM-DD"
                    />
            </div>
            { this.state.data &&
              this.state.data
              .map((game) =>
                <GameLink key={game.id} game={game} />
              )
            }
            { !this.state.data &&
            <div className="alert alert-warning">{t("no_scorez")}</div>
            }
          </div>
          <div style={{clear:"both"}} />
          <Footer />
        </div>
      )
  }
}