
import firebase from 'firebase';
import './Login.css';
var firebase_config = {
        apiKey: "AIzaSyAKpbS_1z-sS0uGsoRKy9MaOKcJFb_PeJE",
        authDomain: "scorez-be.firebaseapp.com",
        databaseURL: "https://scorez-be.firebaseio.com",
        projectId: "scorez-be",
        storageBucket: "",
        messagingSenderId: "646514247463"
      };

firebase.initializeApp(firebase_config);
export const provider = new firebase.auth.GoogleAuthProvider();
export const auth = firebase.auth();

export default class Identity {

  constructor(props) {
    this.user = null

    this.login = this.login.bind(this)
    this.logout = this.logout.bind(this)

    auth.onAuthStateChanged((user) => {
      if (user) {
        this.user = user
        window.store.dispatch({
          type: "LOG_IN",
          user: user
        })
      }
    });
  }

  getUser() {
    return this.user;
  }

  isCurrentTokenExpired()
  {
    var idToken = window.localStorage.getItem("scorez-token")

    if(idToken && idToken !== "null") {
      var utc_current_time = Date.now()/1000;
      var token_meta_data = JSON.parse(atob(idToken.split(".")[1]))
      // console.log(token_meta_data);
      var time_until_exp = token_meta_data.exp - utc_current_time
      // console.log("Time until exp: " + time_until_exp)

      if(time_until_exp > 30) {
        return false
      }
    }
    return true
  }

  getToken(successCallback, failureCallback = () => {}) {
    var user = this.user
    var current_token_expired = this.isCurrentTokenExpired()
    if(user && current_token_expired)
    {
      user.getIdToken(/* forceRefresh */ true)
        .then(function(idToken) {
          window.localStorage.setItem("scorez-token", idToken)
          successCallback(idToken)
        })
    } else if(!current_token_expired) {
      var idToken = window.localStorage.getItem("scorez-token")
      successCallback(idToken)
    } else {
      // console.log(user)
      console.log("FAILURE TOKEN!")
      failureCallback()
    }
  }

  logout() {
    auth.signOut()
      .then(() => {
        this.user = null
        window.localStorage.setItem("scorez-token", null)
        window.store.dispatch({
          type: "LOG_OUT"
        })
      });
  }

  login() {
    auth.signInWithPopup(provider)
      .then((result) => {
        const user = result.user;
        console.log(user)
        this.user = user

        window.store.dispatch({
          type: "LOG_IN",
          user: user
        })
      });
  }
}