import { TweenMax, TimelineMax } from 'gsap';
import AbstractAnimator from './AbstractAnimator'

export default class DropAnimator extends AbstractAnimator {

    constructor(props) {
        super(props)

        this.transition_time = 1
        this.show_before = false
    }

    animate() {
        this.is_animating = true
        this.vars.tl = new TimelineMax({ delay:0.5,
                                         repeat:0,
                                         repeatDelay:2,
                                         onUpdate:this.updateScore.bind(this)});

        this.items.forEach((item) => item.positionBefore = 1)

        this.items.sort(this.sorting.reverseSortAfterTeamName)
          .forEach((item, index) =>
            {
                this.vars.tl.add(TweenMax
                    .set(item.scoreitemElement,
                        {
                            visibility: "visible",
                            x: window.innerWidth/2 - item.scoreitemElement.offsetWidth/2,
                            y: -200,
                        }))

                this.vars.tl.add(function(item){ item.updateCount = Date.now() }.bind(this, item))

                // Scroll to position
                var yPos = this.columnLayoutY(item, item.positionAfter)
                this.vars.tl.add(function(item){ window.scrollTo(0, yPos) }.bind(this, item))

                this.vars.tl.add(TweenMax
                    .to(item.scoreitemElement, 1,
                        {
                            x: this.columnLayoutX(item, item.positionAfter),
                            y: yPos
                        }), "-= 0.1")

            }
        )

        this.vars.tl.add(TweenMax.to(this, 5))
        this.vars.tl.add(function(item){
            this.is_animating = false
            this.has_animated = true
        }.bind(this))
    }
}