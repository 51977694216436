function isRoundUsedForTieBreaker(rules, round_index) {
    var used_for_tie_breaker = false
    rules.forEach((rule) => {
      if(rule.rule === "tie-breaker" && rule.round === round_index) {
        used_for_tie_breaker = true
      }
    })
    return used_for_tie_breaker
}

export default class Sorting {

  constructor(props) {
    this.exaequo_rules = []
    this.before = 0
    this.after = 0

    this.sortBefore = this.sortBefore.bind(this)
    this.sortAfter = this.sortAfter.bind(this)
    this.sortBeforeTeamName = this.sortBeforeTeamName.bind(this)
    this.sortAfterTeamName  = this.sortAfterTeamName.bind(this)
    this.randomOffsetSortAfter = this.randomOffsetSortAfter.bind(this)

    this.reverseSortAfterTeamName = (a, b) => -this.sortAfterTeamName(a, b)
    this.reverseSortBeforeTeamName = (a, b) => -this.sortBeforeTeamName(a, b)
  }

  setBeforeAfter(before, after) {
    this.before = before
    this.after = after
  }

  setExAequoRules(rules) {
    this.exaequo_rules = rules
  }

  applyExAequoRules(aTeam, bTeam, round) {
    var exaequo_result = 0
    var BreakException = {};
    try {
        this.exaequo_rules.forEach((rule) => {

          var comp = 0
          var a_score, b_score, i, new_comp;

          if(rule.rule === "tie-breaker")
          {

            var a_dist = Math.abs(aTeam.row[rule.round] - rule.answer)
            var b_dist = Math.abs(bTeam.row[rule.round] - rule.answer)

            if(!isNaN(a_dist) &&
               !isNaN(b_dist))
            {
              comp = a_dist - b_dist
            }

          } else if (rule.rule === "selected-round") {

            a_score = aTeam.row[rule.round]
            b_score = bTeam.row[rule.round]
            if(!isNaN(a_score) &&
               !isNaN(b_score))
            {
              comp = b_score - a_score
            }

          } else if (rule.rule === "first-to-last") {

            for(i = 1; i <= round; i++) {
              a_score = aTeam.row[i]
              b_score = bTeam.row[i]
              // TODO a_score and b_score checks are incorrect if the score is 0
              if(a_score && b_score && !isNaN(a_score) && !isNaN(b_score) &&
                  !isRoundUsedForTieBreaker(this.exaequo_rules, i)
                ) {
                new_comp = b_score - a_score
                if(new_comp !== 0) {
                  comp = new_comp
                  break
                }
              }
            }

          } else if (rule.rule === "last-to-first") {

            for(i = round; i > 0; i--) {
              a_score = aTeam.row[i]
              b_score = bTeam.row[i]
              // TODO a_score and b_score checks are incorrect if the score is 0
              if(a_score && b_score && !isNaN(a_score) && !isNaN(b_score) &&
                  !isRoundUsedForTieBreaker(this.exaequo_rules, i)
                ) {
                new_comp = b_score - a_score
                if(new_comp !== 0) {
                  comp = new_comp
                  break
                }
              }
            }

          }

          if(comp !== 0)
          {
            exaequo_result = comp
            throw BreakException
          }
        })
    } catch(e) {
        if (e !== BreakException) throw e;
    }

    return exaequo_result
  }

  sortAfter(aTeam, bTeam) {
    let comp = bTeam.scoreAfter - aTeam.scoreAfter

    if(comp === 0) {
        return this.applyExAequoRules(aTeam, bTeam, this.after)
    }
    return comp
  }

  sortBefore(aTeam, bTeam) {
    let comp = bTeam.scoreBefore - aTeam.scoreBefore

    if(comp === 0) {
        return this.applyExAequoRules(aTeam, bTeam, this.before-1)
    }
    return comp
  }

  sortAfterTeamName(aTeam, bTeam) {
    let comp = this.sortAfter(aTeam, bTeam)

    if(comp === 0) {
      return aTeam.team.localeCompare(bTeam.team)
    } else {
      return comp
    }
  }

  sortBeforeTeamName(aTeam, bTeam) {
    let comp = this.sortBefore(aTeam, bTeam)

    if(comp === 0) {
      return aTeam.team.localeCompare(bTeam.team)
    } else {
      return comp
    }
  }

  applyExAequoRulesAndTeamName(aTeam, bTeam, round) {
    let comp = this.applyExAequoRules(aTeam, bTeam, round)

    if(comp === 0) {
      return aTeam.team.localeCompare(bTeam.team)
    } else {
      return comp
    }
  }

  randomOffsetSortAfter(aTeam, bTeam) {
    let comp = aTeam.positionAfter - bTeam.positionAfter

    return comp - 2 + Math.floor(Math.random() * 6)
  }

}

export {isRoundUsedForTieBreaker, Sorting}