import {lang} from './Translate';

export default function scorezPath(path)
{
    return window.rootFolder + '/' + lang + path
}

function relativeScorezPath(path)
{
    return "/" + lang + path
}

export {scorezPath, relativeScorezPath};