import React, { Component } from 'react';
import Superagent from 'superagent';
import GameHeader from './GameHeader';
import {relativeScorezPath} from '../Helpers';
import './GameHome.css';

import {t} from '../Translate';
var FontAwesome = require('react-fontawesome');

export default class GameHome extends Component {

  constructor(props) {
    super(props);

    this.state = {
        game: this.props.match.params.game,
        fields_route: window.apiUrl + "/scorez-api/fields.php",
        teams: false,
        role: false,
        teamFocus: 0
    };

    this.game_data = props.game_data
    this.handleError  = props.handleError;
    this.prev_update = [0, ""]
  }

  componentDidMount() {
    this.updateStore()
    this.unsubscribe = window.store.subscribe(this.updateStore.bind(this))
  }

  updateStore() {

    if(!this.state.teams) {
      window.identity.getToken(
        /* Success callback */
        (idToken) => {
          this.loadFields(idToken)
        },
        /* Failure callback */
        this.loadFields.bind(this, null)
      )
    }

    this.setState({
      role: window.store.getState().role
    })

  }

  loadFields(idToken) {
    var post_data = {
      col: 0
    }

    var callback = (data) => {
      this.setState({
        teams: data
      })
    }

    if(idToken) {
      this.game_data.loadFields(idToken, callback, post_data)
    } else {

      this.game_data.loadGame((game) => {
        if(game.public === 1)
        {
          this.game_data.loadFields(null, callback, post_data)
        } else {
          // Wait for the auth provider to realise a user is logged in
          // Don't show anything to the user?
        }
      })

    }
  }

  componentWillUnmount() {
    this.unsubscribe()
  }

  updateTeamState(index, event) {
    var teams = this.state.teams
    teams[index] = [event.target.value]
    this.setState({
      teams: teams
    })
  }

  onKeyUpTeam(index, event) {
    if (event.key === "Enter")
    {
      this.updateTeam(index, event.target.value.trim())
      this.setTeamFocus(index+1)
    }
  }

  onBlurTeam(index, event) {
    this.updateTeam(index, event.target.value.trim())
  }

  updateTeam(index, team) {
    if (this.prev_update[0] === index && this.prev_update[1] === team)
    {
      return
    }
    this.prev_update = [index, team]

    window.identity.getToken(function(idToken) {
      Superagent
        .post(this.state.fields_route)
        .send({"game_id": this.game_data.getGameId(), "row": index, "col": 0, "value": team, "user": idToken})
        .set('Accept', 'application/json')
        .end(function(err,res){
          //console.log(err);
          //console.log(res);
          if(!this.handleError(t("error_updating_field"), err, res))
          {
          }
        }.bind(this));
    }.bind(this))
  }

  canEdit()
  {
    return this.state.role === "owner" || this.state.role === "editor"
  }

  setTeamFocus(index)
  {
    this.refs["team_" + index].focus()
    this.setState({
      teamFocus: index
    })
  }

  deleteTeamAsync(index)
  {
    window.identity.getToken(function(idToken) {
      Superagent
        .post(this.state.fields_route)
        .send({"game_id": this.game_data.getGameId(), "delete_row": index, "user": idToken})
        .set('Accept', 'application/json')
        .end(function(err,res){
          //console.log(err);
          //console.log(res);
          if(!this.handleError(t("error_updating_field"), err, res))
          {
            this.handleError(t("team_deleted"), true, null, "success")
            //console.log("Team deleted!")
          }
        }.bind(this));
    }.bind(this))
  }

  deleteTeam(index)
  {
    if(!window.confirm(t("delete_team_confirmation")))
    {
      return
    }
    let teams = this.state.teams
    teams.splice(index, 1)
    this.setState({
      teams
    })
    setTimeout(this.deleteTeamAsync.bind(this, index), 0)
  }

  teamInput(index)
  {
    // Ignore first value (header value)
    if (index === 0)
    {
      return (<div key="first_element" />)
    }

    var team = ""
    if (this.state.teams && index in this.state.teams)
    {
      team = this.state.teams[index][0]
    }
    return (
      <input
              key={"team_" + index}
              name={"team_" + index}
              ref={"team_" + index}
              type="text"
              className="form-control"
              placeholder="Team"
              value={team}
              autoFocus={(this.state.teamFocus === index)}
              onFocus={this.setTeamFocus.bind(this, index)}
              onChange={this.updateTeamState.bind(this, index)}
              onBlur={this.onBlurTeam.bind(this, index)}
              onKeyUp={this.onKeyUpTeam.bind(this, index)}
              ></input>
    )
  }

  renderLinks() {
    return (
      <div>
        <a href={relativeScorezPath("/game/scoreboard/" + this.state.game)}>{ t("scoreboard") }</a> | <a href={relativeScorezPath("/game/sheet/" + this.state.game)}>{ t("sheet") }</a>
      </div>
    )
  }

  render() {
    var empty_teams = []
    var length = 1
    if (this.state.teams)
    {
      length += this.state.teams.length
    }
    for (var i = 0; i < length; i++)
    {
      empty_teams.push("")
    }
    return (
      <div>
        <GameHeader game_data={this.game_data} handleError={this.props.handleError} />
        { this.state.teams &&
          <div className="team-list">
            <h3>{ t("teams") }</h3>
            { this.canEdit() && <p>{ t("teams_explanation") }</p> }
            <div className="container">
              { this.canEdit() && empty_teams
                .map((team, index) =>
                  <div key={"team-wrap-" + index}>
                  <div className="row">
                    <div className="col-8">
                      { this.teamInput(index) }
                    </div>
                    <div className="col-1">
                      { index > 0 &&
                        <FontAwesome
                              onClick={this.deleteTeam.bind(this, index)}
                              name="trash" />
                      }
                    </div>
                  </div>
                  </div>
              )}
              { !this.canEdit() && this.state.teams
                .map((team, index) =>
                <div key={"wrap-"+index}>
                { index !== 0 &&
                  <div key={index} className="team-listitem">
                  {team}
                  </div>
                }
                </div>
              )}
             </div>
          </div>
        }
        <div style={{"clear": "both"}} />
        <br />
        <br />
        <br />
      </div>
      )
  }

}