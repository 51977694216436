import { TweenMax, TimelineMax } from 'gsap';
import AbstractAnimator from './AbstractAnimator'

export default class BlackHoleAnimator extends AbstractAnimator {

  constructor(props) {
    super(props)

    this.transition_time1 = 2
    this.transition_time2 = 2
    this.transition_time = this.transition_time1 + this.transition_time2
  }

  animate() {
    this.is_animating = true
    this.vars.tl = new TimelineMax({
      delay: 1,
      repeat: 0,
      repeatDelay: 2,
      onUpdate: this.updateScore.bind(this)
    });

    // Calc center
    let weightedCenter = this.items.reduce((acc, item) => {
      acc.x += this.columnLayoutX(item, item.positionAfter)
      acc.y += this.columnLayoutY(item, item.positionAfter)
      return acc
    }, {x: 0, y: 0})

    weightedCenter.x /= this.items.length
    weightedCenter.y /= this.items.length

    this.vars.tl.add("StartPoint");

    this.items
      .sort(this.sorting.reverseSortAfterTeamName)
      .forEach((item, index) => {

        this.vars.tl.add(function (item) { item.updateCount = Date.now() }.bind(this, item),
          "StartPoint")

        this.vars.tl.add(TweenMax
          .to(item.scoreitemElement, this.transition_time1,
            {
              x: weightedCenter.x,
              y: weightedCenter.y,
              rotation: Math.random()*360,
            }), "StartPoint")

      })

    this.vars.tl.add("MidPoint");

    this.items
      .sort(this.sorting.reverseSortAfterTeamName)
      .forEach((item, index) => {

        this.vars.tl.add(TweenMax
          .to(item.scoreitemElement, this.transition_time2,
            {
              x: this.columnLayoutX(item, item.positionAfter),
              y: this.columnLayoutY(item, item.positionAfter),
              rotation: 0,
            }), "MidPoint")
      })

    this.vars.tl.add(TweenMax.to(this, 5))
    this.vars.tl.add(function (item) {
      this.is_animating = false
      this.has_animated = true
    }.bind(this))
  }
}