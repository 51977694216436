import React, { Component } from 'react';
//import Superagent from 'superagent';

import Title from './Title';
import Nav from './Nav';
import Footer from './Footer';
//import moment from 'moment';

import {t} from './Translate';

export default class Profile extends Component {

  constructor(props) {
    super(props);

    this.state = {
      user: null
    }

    this.handleError = this.props.handleError;
  }

  componentDidMount() {
    this.unsubscribe = window.store.subscribe(this.updateStore.bind(this))
  }

  componentWillUnmount() {
    this.unsubscribe()
  }

  updateStore() {
    var user = window.store.getState().user

    this.setState({
        user: user,
        user_games: false,
    })

    // window.identity.getToken(
    //     /* Success callback */
    //   (idToken) => {
    //     Superagent
    //       .post(this.state.dataRoute)
    //       .send({user: idToken})
    //       .set('Accept', 'application/json')
    //       .end(function(err,res){
    //         if (!this.handleError("Error fetching games", err, res)) {
    //             this.setState({
    //                 user_games: res.body
    //             });
    //         }
    //       }.bind(this))
    //     })
  }

  render() {
    return (
        <div id="profile">
          <Title />
          <Nav />
            <div className="scorez-content">
            <h2>Profile</h2>
            <p>{t("name")}: {this.state.user && 
                this.state.user.displayName}</p>
            </div>
          <Footer />
        </div>
      )
  }
}