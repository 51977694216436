import React, { Component } from 'react';
import logo from '../logo.svg';
import './Scoreboard.css';
import ControlledDropAnimator from '../animators/ControlledDropAnimator';
import ControlledDropReverseAnimator from '../animators/ControlledDropReverseAnimator';
import DropAnimator from '../animators/DropAnimator';
import ShuffleAnimator from '../animators/ShuffleAnimator';
import ShuffleFakeyAnimator from '../animators/ShuffleFakeyAnimator';
import TeleportAnimator from '../animators/TeleportAnimator';
import SortAnimator from '../animators/SortAnimator';
import BlackHoleAnimator from '../animators/BlackHoleAnimator';
import CircleAnimator from '../animators/CircleAnimator';
import {isRoundUsedForTieBreaker, Sorting} from './Sorting';

import t from '../Translate';

class Scoreboard extends Component {


  constructor(props) {
    super(props);

    this.state = {
        rounds: false,
        first_round: 0,
        last_round: 0,
        before: false,
        after: false,
        animate: false,
        selected_animator: 3,
    };

    this.game_data = props.game_data

    // The table with all scores, teams, round names
    this.data = false
    this.rules = false

    this.sorting = new Sorting()

    this.dropAnimator = DropAnimator
    this.shuffleAnimator = ShuffleAnimator
    this.sortAnimator = SortAnimator

    this.animators = [
      {
        name: t('animate_drop_controlled'),
        animator: ControlledDropAnimator,
      },
      {
        name: t('animate_drop_reverse_controlled'),
        animator: ControlledDropReverseAnimator,
      },
      {
        name: t('animate_drop'),
        animator: DropAnimator,
      },
      {
        name: t('animate_shuffle1'),
        animator: ShuffleAnimator,
      }, {
        name: t('animate_shuffle2'),
        animator: ShuffleFakeyAnimator,
      },
      {
        name: t('animate_teleport'),
        animator: TeleportAnimator,
      },
      {
        name: t('animate_sort'),
        animator: SortAnimator,
      },
      {
        name: t('animate_black_hole'),
        animator: BlackHoleAnimator,
      },
      {
        name: t('animate_circle'),
        animator: CircleAnimator,
      },
      {
        name: t('animate_random'),
        animator: null,
      },
    ]

    this.animators[this.animators.length-1].animator =
        this.animators[Math.floor(2 + Math.random() * (this.animators.length - 4))].animator

    this.handleError = this.props.handleError


  }

  componentDidMount() {
    this.updateStore()
    this.unsubscribe = window.store.subscribe(this.updateStore.bind(this))
  }

  updateStore() {
    // Is the user actually used anywhere while rendering?
    // Shouldn't we remove the user from the state?
    this.setState({
      user: window.store.getState().user
    })

    this.loadRounds(window.store.getState().user)
    this.loadExAequoRules()
  }

  componentWillUnmount() {
    this.unsubscribe()
  }

  loadScoreBeforeAndAfter() {
    this.game_data.getSettings("before", "loading score before animation", (before) => {
      if(before && before !== "") {
        this.setState({
          before: parseInt(before, 10)
        })
      }
    })

    this.game_data.getSettings("after", "loading score after animation", (after) => {
      if(after && after !== "") {
        this.setState({
          after: parseInt(after, 10)
        })
      }
    })
  }

  loadExAequoRules() {
    this.game_data.getExAequoRules((rules) => {
      if(rules && rules !== "") {
        this.updateExAequoRules(rules)
      } else {
        this.updateExAequoRules([])
      }
    })
  }

  updateExAequoRules(rules) {
    this.sorting.setExAequoRules(rules)
    this.rules = rules
    this.updateRounds()
  }

  updateRounds(data, rules) {
    if(this.data && this.rules) {
      var rounds = []
      var row = this.data[0]

      // Overly complicated way to find to first and last non-empty array index
      var round_indices = row.map((_, index) => index).filter(x => !this.isRoundUsedForTieBreaker(x))
      var first_round = round_indices[0];
      var last_round = round_indices[round_indices.length-1]

      row.forEach((item, index) => {
        if(index !== 0) {
          rounds[index] = item
        }
      })

      var before = this.state.before === false? first_round: this.state.before
      var after = this.state.after === false? first_round: this.state.after

      this.setState({
        first_round,
        last_round,
        before: before,
        after: after,
        rounds
      })

      this.loadScoreBeforeAndAfter()
    }
  }

  loadRounds(user)
  {
    var post_data = {
      row: 0
    }

    this.loadFields(user, (data) => {
      this.data = data
      this.updateRounds()
    }, post_data)
  }

  loadFields(user, callback, post_data={}) {
    if(user) {
         window.identity.getToken(
        /* Success callback */
        (idToken) => {
          this.game_data.loadFields(idToken, callback, post_data)
        })

    } else {

      this.game_data.loadGame((game) => {
        if(game.public === 1)
        {
          this.game_data.loadFields(null, callback, post_data)
        } else {
          // Wait for the auth provider to realise a user is logged in
          // Don't show anything to the user?
        }
      })

    }
  }

  changeBefore(event)
  {
    var before = event.target.value
    this.game_data.getRole((role) => {
      if(role === "owner") {
        this.game_data.updateSettings("before", before, "updating score before animation", () => {
            this.setState({
              before: parseInt(before, 10)
            })
        })
      } else {
        this.setState({
          before: parseInt(before, 10)
        })
      }
    })
  }

  changeAfter(event)
  {
    var after = event.target.value
    this.game_data.getRole((role) => {
      if(role === "owner") {
        this.game_data.updateSettings("after", after, "updating score after animation", () => {
            this.setState({
              after: parseInt(after, 10)
            })
        })
      } else {
        this.setState({
          after: parseInt(after, 10)
        })
      }
    })
  }

  isRoundUsedForTieBreaker(round_index) {
    return isRoundUsedForTieBreaker(this.sorting.exaequo_rules, round_index)
  }

  selection(callback, selected_value) {
    return (
        <select className="form-control"
                onChange={callback}
                value={selected_value}
                >
          { this.state.rounds && this.state.rounds
            .map((value, index) =>
              <option key={"option-" + index}
                      hidden={this.isRoundUsedForTieBreaker(index)}
                      value={index}>{value}
                      </option>
            )}
        </select>
      )
  }

  animatorSelection() {
    return (
      <select className="form-control"
        value={this.state.selected_animator}
        onChange={(event) => {
          this.setState({
            selected_animator: event.target.value
          })
          }
        }
        >
        {this.animators && this.animators
          .map((value, index) =>
            <option key={"option-" + index}
              value={index}>{value.name}
            </option>
          )}
      </select>
    )
  }

  load() {
    console.log("Loading fields")
    this.sorting.setBeforeAfter(this.state.before, this.state.after)

    let scoreRoundsNoTieBreaker = {}
    let columnsWithoutTieBreaker = new Set()
    this.loadFields(this.state.user, (table_data) => {
      var data = []

      // Initialize
      table_data.forEach((row, rowNb) => {
        if(rowNb !== 0)
        {
          data[rowNb] = {"id": rowNb,
                          team: row[0].trim(),
                          row: row,
                          "scoreBefore": 0,
                          "scoreAfter": 0,
                          "updateCount": 0,
                        }
        }
      })

      // Load before and after data
      table_data.forEach((row, rowNb) => {
        if(rowNb !== 0)
        {
          var floatValue;
          row.forEach((value, colNb) => {
            if(!this.isRoundUsedForTieBreaker(colNb)) {
              if(colNb !== 0 && colNb < this.state.before) {
                floatValue = parseFloat(value)
                if(!isNaN(floatValue))
                {
                  data[rowNb].scoreBefore += parseFloat(value)
                }
              }

              if(colNb !== 0 && colNb <= this.state.after && !isNaN(value)) {
                floatValue = parseFloat(value)
                if(!isNaN(floatValue))
                {
                  let scoreOfRound = parseFloat(value)
                  data[rowNb].scoreAfter += scoreOfRound

                  // Create list for each team with round scores, not used (only) in tie breakers
                  if (!scoreRoundsNoTieBreaker[rowNb]) {
                    scoreRoundsNoTieBreaker[rowNb] = {}
                  }
                  scoreRoundsNoTieBreaker[rowNb][colNb] = scoreOfRound
                  columnsWithoutTieBreaker.add(colNb)
                }
              }
            }
          })
        }
      })

      // Determine for each team, the amount of times they had max. points (of all teams) on a round
      columnsWithoutTieBreaker.forEach((round) => {
        let maxScoreForRound = Object.keys(scoreRoundsNoTieBreaker)
          .reduce((max, rowNb) => Math.max(max, scoreRoundsNoTieBreaker[rowNb][round]), 0)

        if (!isNaN(maxScoreForRound) && maxScoreForRound !== 0) {
          Object.keys(scoreRoundsNoTieBreaker).forEach(
            (rowNb) => {
              if (scoreRoundsNoTieBreaker[rowNb][round] === maxScoreForRound) {
                if (!data[rowNb].medals) {
                  data[rowNb].medals = []
                }
                data[rowNb].medals.push(round)
              }
            }
          )
        }
      })

      data = data.filter(function(item) {
        return item.team !== ""
      })

      this.setState({
        data
      })
    })
  }

  reset() {
    this.setState({
      data: false
    })
  }

  startAnimation() {
    this.setState({
      animate: true
    })
  }

  roundsReady() {
    return this.state.rounds
  }

  render() {
    var Animator = this.animators[this.state.selected_animator].animator

    return (
      <div className="wrap-div">
        <div className="scoreboard-scorez-advert">
          <img alt="logo" src={logo} className="scoreboard-scorez-logo" />
          <span>scorez.be</span>
        </div>
        <div className="wrap-div" key="settings">
          <div className="scoreboard-settings-container">
          { !this.state.animate &&
            <div>
              <h1 className="scoreboard-title">{ t("scoreboard") }</h1>
              <p className="scoreboard-title-tip">{ t("fullscreen") }</p>
              <p style={{"fontSize": "0.8rem"}}>
                {t("scoreboard_explanation")}
              </p>
            </div>
          }
          { this.roundsReady() &&
            <table className="scoreboard-settings">
            <tbody>
            { !this.state.data &&
              <tr>
                <th>{ t("score_before") }</th>
                <td>{this.selection(this.changeBefore.bind(this), this.state.before)}</td>
              </tr>
            }
            { !this.state.data &&
              <tr>
                <th>{ t("score_after") }</th>
                <td>{this.selection(this.changeAfter.bind(this), this.state.after)}</td>
              </tr>
            }
            {!this.state.data &&
              <tr>
                <th>{t("animation")}</th>
                <td>{this.animatorSelection()}</td>
              </tr>
            }
            { !this.state.data &&
              <tr>
                <th></th>
                <td><input type="button"
                           className="btn btn-primary"
                           onClick={this.load.bind(this)}
                           value={ t("load") }
                           style={{width: "100%"}}
                           ></input></td>
              </tr>
            }
            { this.state.data && !this.state.animate &&
              <tr>
                <td><input  type="button"
                            className="btn btn-primary"
                            onClick={this.reset.bind(this)}
                            value={ t("reset") }
                            style={{width: "100%"}} /></td>
                <td><input  type="button"
                            className="btn btn-primary"
                            disabled={!this.state.data}
                            onClick={this.startAnimation.bind(this)}
                            value={ t("animate") }
                            style={{width: "100%"}} /></td>
              </tr>
            }
            </tbody>
            </table>
          }
          </div>
          <div className="scoreboard" id="scoreboard" ref="scoreboard">
          { this.state.data &&
            <Animator data={this.state.data}
                      animate={this.state.animate}
                      sorting={this.sorting}
                      />
          }
          </div>
        </div>
      </div>
    );
  }
}

export default Scoreboard;
