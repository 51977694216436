
var supported_languages = ["en", "nl"];
var lang = selectLanguage();

var d = {
    "en" : {
        /* Nav */
        "change_language": "Language",
        "browse": "All Scorez",
        "my_games": "My Scorez",
        "contact": "Contact",

        /* Home */
        "about": "About Scorez",
        "no_scorez": "No scorez could be found",
        "scorez_is": "Scorez is an online, animated scoreboard.",
        "scorez_how": "Create your scorez, add teams, add rounds, give your contestants points and show them how they did.",
        "log_in_to_get_started": "Log in to get started.",
        "latest_public_scorez": "Recent Scorez",
        "latest_user_scorez": "My Scorez",

        /* Buying codes */
        "buy_game_codes": "Buy game codes",
        "buy_code_explanation": "To create new games you will have to enter a code. Here you can buy codes. These codes can be given to other users as well.",
        "buy_codes": "Buy 2 codes for €4.99",

        /* Create Game */
        "code": "Code",
        "code_tooltip": "Enter the 6-character code. Without this code, you cannot create scorez.",
        "code_placeholder": "ABC123",
        "name_placeholder": "My fantastic event",
        "organizer_placeholder": "My organisation",
        "location_placeholder": "The location",
        "name": "Name",
        "organizer": "Organizer",
        "location": "Location",
        "date": "Date",
        "create": "Create",
        "create_game": "Create Scorez",
        "create_game_explanation": "Create your Scorez-game, add teams and rounds, optionally configure your ex-aequo rules, enter the scored points and animate the intermediate score.",

        "warning_game_cannot_be_changed": "Warning: These game details cannot be changed.",

        /* Browse Games */
        "list_scorez": "Scorez by Date",
        "scorez_between": "Scorez between",
        "and": "and",
        "all_previous_and_next_scorez": "All scorez 2 weeks before the selected date",


        /* Log in */
        "log_in": "Log In",
        "log_out": "Log Out",

        /* Donation */
        "no_ads": "No ads are being shown on this site.",
        "relies_on": "This site relies on donations.",
        "please_donate": "If you wish to support us, please donate.",

        /* Game Menu */
        "game": "Game",
        "scoreboard": "Scoreboard",
        "sheet": "Spreadsheet",
        "settings": "Settings",
        "export_csv": "Export",
        "home": "Home",

        /* Game Home */
        "teams": "Teams",
        "teams_explanation": "Enter the participating teams.",
        "enter_scorez": "Enter Scorez",
        "view_scorez": "View Scorez",
        "animate_scoreboard": "Animate Scoreboard",
        "delete_team_confirmation": "Are you sure, you want to delete the team?",
        "team_deleted": "Team deleted",

        /* Game Header */
        "on": "on",
        "error": "Error",
        "info": "Info",
        "unauthorized": "Unauthorized",
        "loading": "Loading",
        "error_loading_game": "Error loading game",

        /* Game Sheet */
        "remove_team_confirmation": "Are you sure you want to remove team ",
        "remove_round_confirmation": "Are you sure you want to remove round ",
        "error_updating_field": "Error updating field",
        "error_not_a_number": "Not a number",
        "remove": "Remove",
        "round": "Round",
        "add_team": "Add Team",
        "add_round": "Add Round",
        "lock_edit": "Lock Edit",
        "unlock_edit": "Unlock Edit",
        "not_logged_in": "Not logged in",
        "total": "Total",
        "edit_mode": "Edit Mode",
        "display_mode": "Display Mode",
        "loading_data": "loading data",
        "edit": "Edit",

        /* Export */
        "csv_export_use": "The exported .csv file can be imported into a spreadsheet.",

        /* Scoreboard */
        "load": "Load",
        "reset": "Reset",
        "animate": "Animate",
        "score_before": "Score before",
        "score_after": "Score after",
        "scoreboard_explanation": "Animate your scoreboard between the scorez before a given round to the scorez after a given round.",
        "scoreboard_explanation_2": "For animation 'on command', you click on a team to animate the next team.",
        "fullscreen": "Press F11 or Control+Cmd+F for fullscreen.",
        "highest_score_on_round": "Highest score on round",

        /* Game Settings */
        "no_rules": "No rules selected",
        "ex_aequo_rules": "Ex-Aequo Rules",
        "available_rules": "Available Rules",
        "selected_rules": "Selected Rules",
        "selected_rules_desc": "The selected rules will be applied in order.",
        "add_rule": "Add",
        "remove_rule": "Remove",
        "save_exaequo_rules": "Save Ex-Aequo Rules",
        "save_successful": "Ex-Aequo Rules Saved",
        "last-to-first": "Last round to first round",
        "first-to-last": "First round to last round",
        "tie-breaker": "Tie breaker",
        "selected-round": "Selected round",
        "answer": "Answer",
        "invalid": "Invalid",
        "last-to-first-desc": "The team who scores higher on the last round, is ranked higher. If the scores are equal, the result of the second to last round is checked. Et cetera.",
        "first-to-last-desc": "The team who scores higher on the first round, is ranked higher. If the scores are equal, the result of the second round is checked. Et cetera.",
        "tie-breaker-desc": "The selected round contains the result of a tie breaker. The team closest to the correct result, is ranked higher.",
        "selected-round-desc": "The team which scores highest on the selected round, is ranked higher. Use this consecutively to define a custom order of rounds.",
        "delete_game": "Delete Game",
        "enter_delete_game": "Enter 'Yes, delete game'",
        "yes_delete_game": "Yes, delete game",
        "delete_game_confirmation": "Are you sure, you want to delete the game?",
        "game_deleted": "Game deleted",
        "deleting_game": "deleting game",
        "edit_game": "Edit Game",
        "public": "Public",
        "game_edited": "Game edited",
        "provide_name": "Please provide a name",
        "provide_organizer": "Please provide an organizer",
        "editing_game": "editing game",

        /* Animators */
        "animation": "Animation",
        "animate_next": "Next",
        "animate_drop": "Drop",
        "animate_drop_controlled": "On Command (high → low)",
        "animate_drop_reverse_controlled": "On Command (low → high)",
        "animate_shuffle1": "Shuffle 1",
        "animate_shuffle2": "Shuffle 2",
        "animate_teleport": "Fade",
        "animate_sort": "Sort",
        "animate_black_hole": "Black Hole",
        "animate_circle": "Circle",
        "animate_random": "Random",


    },

    "nl" : {
        /* Nav */
        "change_language": "Language",
        "browse": "Alle Scorez",
        "my_games": "Mijn Scorez",
        "contact": "Contact",

        /* Home */
        "about": "Over Scorez",
        "no_scorez": "No scorez could be found",
        "scorez_how": "Maak je scorez, voeg teams toe, voeg rondes toe, geef je kandidaten punten en toon ze hoe goed ze het gedaan hebben.",
        "construction": "Deze site is nog in opbouw.",
        "scorez_is": "Scorez is een online, geanimeerd scorebord.",
        "log_in_to_get_started": "Log in om van start te gaan",
        "latest_public_scorez": "Recente Scorez",
        "latest_user_scorez": "Mijn Scorez",

        /* Buying codes */
        "buy_game_codes": "Koop codes",
        "buy_code_explanation": "Om een Scorez-spel aan te maken, heb je een code nodig. Hier kan je deze codes kopen. Je kan ze ook delen met anderen.",
        "buy_codes": "Koop 2 codes voor €4.99",

        /* Create Game */
        "code": "Code",
        "code_tooltip": "Geef de 6-karakter code in. Zonder deze code, kan u geen scorez aanmaken.",
        "code_placeholder": "ABC123",
        "name_placeholder": "Mijn fantastisch evenement",
        "organizer_placeholder": "Mijn organisatie",
        "location_placeholder": "De locatie",
        "name": "Naam",
        "organizer": "Organisator",
        "location": "Locatie",
        "date": "Datum",
        "create": "Maak",
        "create_game": "Maak Scorez",
        "create_game_explanation": "Maak je Scorez-spel aan, voeg teams en rondes toe aan je spel, configureer eventueel je ex-aequo regels, geef de punten in en animeer de tussenstanden.",
        "warning_game_cannot_be_changed": "Waarschuwing: Deze gegevens kunnen niet meer worden gewijzigd.",

        /* Browse Games */
        "list_scorez": "Scorez volgens Datum",
        "scorez_between": "Scorez tussen",
        "and": "en",
        "all_previous_and_next_scorez": "Alle scorez 2 weken voor de geselecteerde datum",

        /* Log in */
        "log_in": "Log In",
        "log_out": "Log Uit",

        /* Donation */
        "no_ads": "Er worden geen advertenties getoond.",
        "relies_on": "Deze site steunt op donaties.",
        "please_donate": "Indien u ons wilt steunen, gelieve te doneren.",

        /* Game Menu */
        "game": "Spel",
        "scoreboard": "Scorebord",
        "sheet": "Spreadsheet",
        "settings": "Instellingen",
        "export_csv": "Exporteer",
        "home": "Thuis",

        /* Game Home */
        "teams": "Teams",
        "teams_explanation": "Geef de deelnemende teams in.",
        "enter_scorez": "Geef Scorez in",
        "view_scorez": "Bekijk Scorez",
        "animate_scoreboard": "Animeer Scorebord",
        "delete_team_confirmation": "Bent u zeker dat u het team wil verwijderen?",
        "team_deleted": "Team verwijderd",

        /* Game Header */
        "on": "op",
        "error": "Fout",
        "info": "Info",
        "unauthorized": "Ongeautoriseerd",
        "loading": "Laden",
        "error_loading_game": "Fout bij het laden van het spel",

        /* Game Sheet */
        "remove_team_confirmation": "Bent u zeker dat u dit team wil verwijderen: ",
        "remove_round_confirmation": "Bent u zeker dat u deze ronde wil verwijderen: ",
        "error_updating_field": "Fout bij het updaten van een veld",
        "error_not_a_number": "Geen getal",
        "remove": "Verwijder",
        "round": "Ronde",
        "add_team": "Extra Team",
        "add_round": "Extra Ronde",
        "lock_edit": "Sluit Bewerking Af",
        "unlock_edit": "Open Bewerking",
        "not_logged_in": "Niet ingelogd",
        "total": "Totaal",
        "edit_mode": "Bewerken",
        "display_mode": "Weergave",
        "loading_data": "laden data",
        "edit": "Bewerk",

        /* Scoreboard */
        "load": "Laden",
        "reset": "Herstel",
        "animate": "Animeren",
        "score_before": "Score voor",
        "score_after": "Score na",
        "scoreboard_explanation": "Animeer het scorebord tussen de stand voor een gegeven ronde tot de stand na een gegeven ronde.",
        "scoreboard_explanation_2": "Bij animatie 'op commando', klik je op een team om het volgende weer te geven.",
        "fullscreen": "F11 of Control+Cmd+F voor weergave op een volledig scherm.",
        "highest_score_on_round": "Hoogste score op ronde",

        /* Export */
        "csv_export_use": "Het geëxporteerde .csv bestand kan worden geïmporteerd in een spreadsheet.",

        /* Game Settings */
        "no_rules": "Geen regels geselecteerd",
        "ex_aequo_rules": "Ex-Aequo Regels",
        "available_rules": "Beschikbare Regels",
        "selected_rules": "Geselecteerde Regels",
        "selected_rules_desc": "De geselecteerde regels worden in volgorde toegepast.",
        "add_rule": "Selecteer",
        "remove_rule": "Verwijder",
        "save_exaequo_rules": "Ex-Aequo Regels Opslaan",
        "save_successful": "Ex-Aequo Regels Opgeslagen",
        "last-to-first": "Laatste ronde naar eerste ronde",
        "first-to-last": "Eerste ronde naar laatste ronde",
        "tie-breaker": "Schiftingsvraag",
        "selected-round": "Geselecteerde ronde",
        "answer": "Antwoord",
        "invalid": "Ongeldig",
        "last-to-first-desc": "Het team met de hoogste score op de laatste ronde, wordt hoger gerangschikt. Indien de score identiek is, wordt de voorlaatste ronde gecontroleerd. Enzovoort.",
        "first-to-last-desc": "Het team met de hoogste score op de eerste ronde, wordt hoger gerangschikt. Indien de score identiek is, wordt de tweede ronde gecontroleerd. Enzovoort.",
        "tie-breaker-desc": "De geselecteerde ronde bevat het resultaat van een schiftingsvraag. Het team het dichtst bij het juiste antwoord, wordt hoger gerangschikt.",
        "selected-round-desc": "Het team met de hoogste score op de geselecteerde ronde, wordt hoger gerangschikt. Herhaal deze regel om een eigen volgorde te definiëren.",
        "delete_game": "Verwijder Scorez",
        "enter_delete_game": "Geef 'Ja, verwijder de scorez' in",
        "yes_delete_game": "Ja, verwijder de scorez",
        "delete_game_confirmation": "Bent u zeker dat u de scorez wilt verwijderen?",
        "game_deleted": "Scorez zijn verwijderd",
        "deleting_game": "verwijderen van de scorez",
        "edit_game": "Bewerk Scorez",
        "public": "Publiek",
        "game_edited": "Scorez bewerkt",
        "provide_name": "Gelieve een naam in te vullen",
        "provide_organizer": "Gelieve een organisator in te vullen",
        "editing_game": "bewerken van het spel",

        /* Animators */
        "animation": "Animatie",
        "animate_next": "Volgende",
        "animate_drop": "Vallen",
        "animate_drop_controlled": "Op Commando (hoog → laag)",
        "animate_drop_reverse_controlled": "Op Commando (laag → hoog)",
        "animate_shuffle1": "Shuffle 1",
        "animate_shuffle2": "Shuffle 2",
        "animate_teleport": "Fade",
        "animate_sort": "Sorteren",
        "animate_black_hole": "Zwart Gat",
        "animate_circle": "Cirkel",
        "animate_random": "Willekeurig",

    }
}

export default function t(key)
{
    if(d[lang])
    {
        if(d[lang][key])
        {
            return d[lang][key];
        }
        else
        {
            console.log("Key " + key + " missing for language " + lang)
            if(d["en"][key])
            {
                return d["en"][key]
            }
            else
            {
                return "Missing [ " + key + " ]"
            }
        }
    }
    else
    {
        console.log("Language " + lang + " missing from dictionary");
    }
}

function selectLanguage()
{
    var path_split = window.location.pathname.split('/')

    if(path_split.length === 0)
    {
        return "nl"
    }

    if(path_split[0] === "")
    {
        path_split.splice(0 , 1)
    }

    if(path_split.length === 0)
    {
        return "nl"
    }

    var lang_candidate = path_split[0]

    if(supported_languages.indexOf(lang_candidate) > -1)
    {
        return lang_candidate
    }

    return "nl";
}

export {t, lang};
