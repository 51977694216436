import { TweenMax, TimelineMax } from 'gsap';
import AbstractAnimator from './AbstractAnimator'

export default class ShuffleAnimator extends AbstractAnimator {

    constructor(props) {
        super(props)

        this.transition_time = 8
    }

    animate() {
        this.is_animating = true
        this.vars.tl = new TimelineMax({
            delay: 1 + this.transition_time,
            repeat: 0,
            repeatDelay: 2,
            onUpdate: this.updateScore.bind(this)
        });

        this.items
            .sort(this.sorting.reverseSortAfterTeamName)
            .forEach((item, index) => {
                this.vars.tl.add(function (item) { item.updateCount = Date.now() }.bind(this, item),
                    "-=" + (this.transition_time - 0.05))

                this.vars.tl.add(TweenMax
                    .to(item.scoreitemElement, this.transition_time,
                        {
                            x: this.columnLayoutX(item, item.positionAfter),
                            y: this.columnLayoutY(item, item.positionAfter)
                        }), "-=" + (this.transition_time - 0.05))

            }
            )

        this.vars.tl.add(TweenMax.to(this, 5))
        this.vars.tl.add(function (item) {
            this.is_animating = false
            this.has_animated = true
        }.bind(this))
    }
}