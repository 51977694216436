import React, { Component } from 'react';
import Superagent from 'superagent';
import './CreateGame.css';
import Title from '../Title';
import Nav from '../Nav';
import Footer from '../Footer';
import t from '../Translate';

import DatePicker from 'react-datepicker';
import moment from 'moment';
import 'react-datepicker/dist/react-datepicker.css';

import GameLink from './GameLink';

export default class CreateGame extends Component {

  constructor(props) {
    super(props);

    this.state = {
      dataRoute: window.apiUrl + "/scorez-api/creategame.php",//"?debug=true"
      date: moment(),
      code: "",
      name: "",
      organizer: "",
      location: "",
      user: null,
      game: false,
    }

    this.handleError = this.props.handleError
  }

  componentDidMount() {
    this.updateStore()
    this.unsubscribe = window.store.subscribe(this.updateStore.bind(this))
  }

  updateStore() {
    this.setState({
      user: window.store.getState().user
    })
  }

  componentWillUnmount() {
    this.unsubscribe()
  }

  create() {
    var date = this.state.date.format("YYYY-MM-DD")

    var failure_callback = () => this.handleError("Could not get ID Token", true, null)

    window.identity.getToken((idToken) => {
        Superagent
          .post(this.state.dataRoute)
          .send({
            user: idToken,
            name: this.state.name,
            code: this.state.code,
            organizer: this.state.organizer,
            location: this.state.location,
            date: date,
          })
          .set('Accept', 'application/json')
          .on('error', (res) => console.log(res))
          .end(function(err, res){
            if (!this.handleError("Error creating game", err, res)) {
                this.setState({
                    game: res.body
                });
            }
          }.bind(this))
        },
        failure_callback
      )
  }

  handleCodeChange(event)
  {
    this.setState({
      code: event.target.value.toUpperCase().substring(0, 6)
    });
  }

  handleNameChange(event)
  {
    this.setState({
      name: event.target.value
    });
  }

  handleOrganizerChange(event)
  {
    this.setState({
      organizer: event.target.value
    });
  }

  handleLocationChange(event)
  {
    this.setState({
      location: event.target.value
    });
  }

  handleDateChange(date)
  {
    this.setState({
      date: date
    });
  }

  render() {
    return (
      <div class="d-flex flex-column h-100">
      <Title />
      <Nav />
      <div className="scorez-content">
      <h2>{t("create_game")}</h2>
      <div className="alert alert-warning" role="alert">
        {t("warning_game_cannot_be_changed")}
      </div>
      { !this.state.game &&
        <table className="create_game">
        <tbody>
          <tr>
            <th>{t("code")}</th>
            <td><input type="text"
                       name="code"
                       className="form-control"
                       placeholder={t("code_placeholder")}
                       data-toggle="tooltip"
                       title={t("code_tooltip")}
                       value={this.state.code}
                       onChange={this.handleCodeChange.bind(this)}
                       /></td>
            <td><button onClick={ () => window.location.href = '/purchase.html'} className="action-btn btn">{ t("buy_codes") }</button></td>
          </tr>
          <tr>
            <th>{t("name")}</th>
            <td><input type="text"
                       name="name"
                       className="form-control"
                       placeholder={t("name_placeholder")}
                       value={this.state.name}
                       onChange={this.handleNameChange.bind(this)}
                       /></td>
          </tr>
          <tr>
            <th>{t("organizer")}</th>
            <td><input type="text"
                       name="organizer"
                       placeholder={t("organizer_placeholder")}
                       className="form-control"
                       value={this.state.organizer}
                       onChange={this.handleOrganizerChange.bind(this)}
                       /></td>
          </tr>
          <tr>
            <th>{t("location")}</th>
            <td><input type="text"
                       name="location"
                       placeholder={t("location_placeholder")}
                       className="form-control"
                       value={this.state.location}
                       onChange={this.handleLocationChange.bind(this)}
                       /></td>
          </tr>
          <tr>
            <th>{t("date")}</th>
            <td><DatePicker
                     selected={this.state.date}
                     onChange={this.handleDateChange.bind(this)}
                     dateFormat="YYYY-MM-DD"
                 /></td>
          </tr>
          <tr>
            <th></th>
            <td><button className="create_game_button" onClick={this.create.bind(this)} type="button">{t("create")}</button></td>
          </tr>

        </tbody>
        </table>
      }
      { this.state.game &&
        <div>
          <p>Game created successfully</p>
          <GameLink key={this.state.game.id} game={this.state.game} />
        </div>
      }
      </div>
      <Footer />
    </div>);
  }
}