import React, { Component } from 'react';
import './Login.css';

import {t} from './Translate';

export default class Login extends Component {

  constructor(props) {
    super(props)

    this.state = {
      user: null,
    }
  }

  componentDidMount() {
    this.updateStore()
    this.unsubscribe = window.store.subscribe(this.updateStore.bind(this))
  }

  updateStore() {
    if (window.store.getState())
    {
      this.setState({
        user: window.store.getState().user
      })
    }
  }

  componentWillUnmount() {
    this.unsubscribe()
  }

  render() {
    return (
      <div className="user">
        <div>
        { this.state.user ?
          <div className="logged_in">
            <button className="logout btn" onClick={window.identity.logout}>{t("log_out")}</button>
          </div>
          :
          <div className="logged_out">
            <button className="login btn"
                    onClick={window.identity.login}>{t("log_in")}</button>
          </div>
        }
        </div>
      </div>
    );
  }
}