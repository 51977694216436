import Superagent from 'superagent';
import t from "../Translate";

function debug(msg) {
  console.log(msg)
}

export default class GameData {

  constructor(props) {
    this.props = props

    this.game = null

    this.game_id = props.game_id;
    this.games_route  = window.apiUrl + "/scorez-api/games.php";
    this.fields_route = window.apiUrl + "/scorez-api/fields.php";
    this.remove_fields_route = window.apiUrl + "/scorez-api/removefields.php";
    this.game_role_route = window.apiUrl + "/scorez-api/gamerole.php";
    this.settings_route = window.apiUrl + "/scorez-api/settings.php";
    this.edit_game_route = window.apiUrl + "/scorez-api/editgame.php";
    this.delete_game_route = window.apiUrl + "/scorez-api/deletegame.php";
    this.handleError  = props.handleError;

    this.role = null;
  }

  getGameId() {
    return this.game_id
  }

  loadGame(callback) {

    var cacheCallback = (body) => {

      this.game = {
        name: body.name,
        organizer: body.organizer,
        date: body.date,
        public: body.public
      }

      callback(this.game)
    }

    if(this.game) {
      callback(this.game)
      return
    }

    Superagent
      .post(this.games_route)
      .send({"id": this.game_id})
      .set('Accept', 'application/json')
      .end(function(err,res) {
        if (!this.handleError(t("error_loading_game"), err, res)) {
          cacheCallback(res.body[0]);
        }
      }.bind(this));
  }

  editGame(public_game, msg) {
    var failure_callback = () => {
      this.handleError("Could not get token", true, null)
    }
    window.identity.getToken(
      /* Success callback */
      (idToken) => {
        this.retryPost(this.edit_game_route,
          {
            user: idToken,
            game_id: this.game_id,
            // name: name,
            // organizer: organizer,
            // date: date,
            public: public_game,
          },
          (body) => {
            this.handleError(t("game_edited"), true, null, "info")
          }, msg)
      },
      /* Failure callback */
      failure_callback
    )
  }

  loadRounds(user, callback) {
    this.loadFields(user, (data) => {
      var rounds = []
      var row = data[0]

      // Overly complicated way to find the first and last non-empty array index
      var round_indices = row.map((_, index) => index).filter(x => true)
      var first_round = round_indices[0];
      var last_round = round_indices[round_indices.length-1]

      row.forEach((item, index) => {
        if(index !== 0) {
          rounds[index] = item
        }
      })

      callback({
        first_round,
        last_round,
        rounds
      })

    }, {row: 0})
  }

  loadFieldsWithUser(callback) {
    var failure_callback = () => {
      this.handleError("Could not get token", true, null)
    }
    window.identity.getToken(
      /* Success callback */
      (idToken) => this.loadFields(idToken, callback),
      /* Failure callback */
      failure_callback
    )
  }

  loadFields(user, callback, post_data = {}) {

    if(!("game_id" in post_data))
    {
      post_data["game_id"] = this.game_id
    }

    if(user)
    {
      post_data["user"] = user
    }

    var parseFieldsCallback = (body) => {
      // Initialize data
      var data = []
      data[0] = [""]

      // Initialize existing columns, columns may have been deleted
      var cols = new Set()
      body.forEach((item) => {

        // Initialize all existing rows, rows may have been deleted
        if(!data[item.row])
        {
          data[item.row] = []
        }

        // Add every existing column to the cols set
        cols.add(item.col)
      })

      data.forEach((row) => {
        cols.forEach((col) => {
          row[col] = ""
        })
      })

      body.forEach((item) => {
        data[item.row][item.col] = item.value
      })

      callback(data);
    }

    this.retryPost(this.fields_route,
                   post_data,
                   parseFieldsCallback,
                   "loading fields")
  }

  removeFields(post_data, callback) {
    Superagent
      .post(this.remove_fields_route)
      .send(post_data)
      .set('Accept', 'application/json')
      .end(function(err, res) {
        //console.log(err);
        //console.log(res);
        if (!this.handleError("Error removing fields", err, res)) {
          callback()
        }
      }.bind(this));
  }

  updateExAequoRules(rules, callback) {
    this.updateSettings("exaequo", rules, "updating ex-aequo rules", callback)
  }

  updateSettings(name, value, msg, callback) {
    var failure_callback = () => {
      this.handleError("Could not get token", true, null)
    }
    window.identity.getToken(
      /* Success callback */
      (idToken) => {
        this.retryPost(this.settings_route,
          {
            user: idToken,
            game_id: this.game_id,
            name: name,
            settings: value
          },
          (body) => {
            callback()
          }, msg)
      },
      /* Failure callback */
      failure_callback
    )
  }

  deleteGame() {
    var success_callback = (game_data, body) => {
      this.handleError(t("game_deleted"), true, null, "info")
      setTimeout(() => this.props.history.push("/"), 1000)
    }
    var failure_callback = () => {
      this.handleError("Could not get token", true, null)
    }
    window.identity.getToken(
      /* Success callback */
      (idToken) => {
        this.retryPost(this.delete_game_route,
          {
            user: idToken,
            game_id: this.game_id
          },
          success_callback, t("deleting_game"))
      },
      /* Failure callback */
      failure_callback
    )
  }

  getExAequoRules(callback) {
    var exaequo_callback = (body) => {
      var rules = body
      if(!rules || rules === "") {
        rules = []
      }
      callback(rules)
    }
    this.getSettings("exaequo", "loading ex-aequo rules", exaequo_callback)
  }

  getSettings(name, msg, callback) {

    this.retryPost(this.settings_route,
      {
        game_id: this.game_id,
        name: name
      },
      (body) => {
        callback(body)
      }, msg)
  }

  getRole(callback) {
    window.identity.getToken((idToken) => {
        this.getRoleForUser(idToken, callback)
      },
      () => callback("none")
    )
  }

  getRoleForUser(user, callback) {
    var cacheCallback = (role) => {
      this.role = role
      callback(role)
    }

    if(this.role) {
      callback(this.role)
    }
    else
    {
      this.retryPost(this.game_role_route,
                     {game_id: this.game_id, user: user},
                     cacheCallback,
                     "getting game role")
    }
  }

  retryPost(url, post_data, callback, msg, max_retries=3) {
    var retries = 0
    var retryCallback = (err, res) => {
      if(err) {
        if(retries >= max_retries) {
          this.handleError("Error " + msg, err, res)
          return
        } else {
          debug("Retrying " + msg)
          retries += 1
          this.post(url, post_data, retryCallback)
        }
      } else {
        debug("Success " + msg)
        callback(res.body)
      }
    }

    this.post(url, post_data, retryCallback)
  }

  post(url, post_data, callback) {

    Superagent
      .post(url)
      .send(post_data)
      .set('Accept', 'application/json')
      .end(callback)

    // request.post({
    //   headers: {'content-type' : 'application/json'},
    //   url: url,
    //   json: post_data
    // }, callback)
  }
}